<template>
  <div id="aboutUs" class="flex-wrap flex-vertical bg1">
    <div class="header before">
      <img :src="require('@/assets/leftArrow.png')" class="leftArrow" @click="toBack()">
      <div class="hearderTitle">{{ type == 1 ? $t('footAll.sjbhzc') : type == 2 ? $t('footAll.yszc') : type == 3
      ? $t('footAll.gsjs') : type == 4 ? $t('footAll.lxwm') : type == 7 ? $t('headGYWM') : '' }}</div>
    </div>
    <div class="fwb" v-html="$t('content')"></div>
  </div>
</template>

<script>
import { getConfig } from '../api'
export default {
  data() {
    return {
        type: this.$route.params.type
    }
  },
  methods: {
    initData() {
      getConfig({
        type: this.type
      }).then((data) => {
        if (data.status == 200) {
          this.$i18n.mergeLocaleMessage('en', { content: data.msg.content_en })
          this.$i18n.mergeLocaleMessage('es', { content: data.msg.content_es })
          this.$i18n.mergeLocaleMessage('tp', { content: data.msg.content_tp })
        }
      })
    },
  },
  mounted() {
    this.initData()
  },

}
</script>


<style scoped>
.bg1 {
  width: 100%;
  height: 100vh;
  background: url("@/assets/bg1.png") no-repeat left top;
  background-size: 95% auto;
}

#aboutUs {
  height: 100vh;
}
.fwb {
  padding: 16px;
}
</style>