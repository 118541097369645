<template>
  <div style="padding-bottom:44px;">
    <div class="header before" style="border-bottom: 1px solid #E2E2E2;background:linear-gradient(to bottom, #68C3ED, #A5E2FF) ;">
      <img :src="require('@/assets/leftArrow.png')" class="leftArrow" @click="toBack()">
      <input type="checkbox" name="checkbox" :checked="data.goodsCollect == 1 ? true : false" @click='(e) => { cancleCollect(e, data.goods_id) }'>
    </div>
   <div style="width: 100%;box-sizing: border-box;padding: 16px 22px 20px 16px;">
    <div style="display: flex;">
      <van-swipe class="my-swipe" style="width: 66px;" :autoplay="1000" indicator-color="#008DD1">
      <van-swipe-item v-for="(item, index) in goods_images" :key="'goods_images_' + index">
        <img lazy-load :src="item" style=" width: 66px;height:66px;object-fit: cover;" />
      </van-swipe-item>
    </van-swipe>
    <div style="flex: 1;margin-left: 13px;">
      <div class="fl">{{ $i18n.locale == 'en' ? data.cateName_en
        : $i18n.locale == 'es' ? data.cateName_es : data.cateName_tp }}</div>
        <div class="flex-con">{{ $i18n.locale == 'en' ? data.goods_name_en
        : $i18n.locale == 'es' ? data.goods_name_es : data.goods_name_tp }}</div>
    </div>
    <div class="price">$ {{ goods_cu_price }}</div>
    
    
    </div>
    
    <div class="" style="margin-top: 20px;">
      <!-- <div class="fl">{{ $i18n.locale == 'en' ? data.cateName_en
        : $i18n.locale == 'es' ? data.cateName_es : data.cateName_tp }}</div>
      <div class="name flex-wrap">
        <div class="flex-con">{{ $i18n.locale == 'en' ? data.goods_name_en
        : $i18n.locale == 'es' ? data.goods_name_es : data.goods_name_tp }}</div>
        <div class="price">$ {{ goods_cu_price }}</div>
      </div> -->
      <!-- <div class="info">{{ $i18n.locale == 'en' ? data.goods_intro_en
        : $i18n.locale == 'es' ? data.goods_intro_es : data.goods_intro_tp }}</div> -->
      <div class="title">{{ $t('goodsObj.xzgg') }}</div>
      <div class="title1">{{ $i18n.locale == 'en' ? data.goods_spec_name_en
        : $i18n.locale == 'es' ? data.goods_spec_name_es : data.goods_spec_name_tp }}</div>
      <div class="top" style="height:30px;margin-top:8px;">
        <div class="top1 flex-wrap">
          <div class="label" v-for="(item, index) in goods_guige1" @click="selectGuige(1, item.spec_id)" :class="{ active: item.spec_id == spec_id }" :key="'guige1_' + index">{{ item.spec_value || '' }}</div>
        </div>
      </div>
      <div class="title1">{{ $t('goodsObj.bz') }}</div>
      <div class="top" style="height:30px;margin-top:7px;">
        <div class="top1 flex-wrap">
          <div class="label" v-for="(item, index) in goods_guige2" @click="selectGuige(2, item.packaging_id)" :class="{ active: item.packaging_id == packaging_id }" :key="'guige2_' + index">{{ item.packaging_name || ''
            }}</div>
        </div>
      </div>
   </div>
      <div class="title" style="margin:20px 0 8px;">{{ $t('goodsObj.cpxq') }}</div>
      <!-- <div class="fwb" v-html="$i18n.locale == 'en' ? data.goods_detail_en
        : $i18n.locale == 'es' ? data.goods_detail_es : data.goods_detail_tp"></div> -->
    </div>
    <div class="bottom flex-wrap">
      <van-stepper v-model="num" :min="1" @change="handleChange" />
      <div class="btn1 flex-con" @click="fnGoodsInCar">{{ $t('goodsObj.jrgwc') }}</div>
      <div class="btn2 flex-con" @click="fnBuy">{{ $t('goodsObj.ljgm') }}</div>
    </div>
  </div>
</template>

<script>
import { goodsDetail, doCollect, addShopCar, getGoodsPriceBySpec } from '../api'
export default {
  data() {
    return {
      goods_guige1: [],
      goods_guige2: [],
      num: 1,
      data: {},
      goods_images: [],
      recommendGoods: [],
      numAll: [],
      spec_id: '',
      packaging_id: '',
      goods_cu_price:0
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    selectGuige(type, idd) {
      if (type == 1) {
        this.spec_id = idd
        let params = {
          spec_id: idd
        }
        getGoodsPriceBySpec(params).then((data) => {
          if (data.status == 200) {
            this.goods_cu_price = data.msg.spec_cu_price
          }
        })
      } else {
        this.packaging_id = idd
      }
    },
    cancleCollect(e, idd) {
      let params = {
        goods_id: idd
      }
      doCollect(params).then((data) => {
        if (data.status == 200) {
          this.data.goodsCollect = data.hasCollect
          this.$toast(data.msg)
        }
      })
    },
    initData() {
      let params = {
        goods_id: this.$route.params.id,
      }
      goodsDetail(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
          this.goods_guige1 = data.msg.spec
          this.goods_guige2 = data.msg.goods_packaging
          this.spec_id = data.msg.spec[0].spec_id
          this.packaging_id = data.msg.goods_packaging[0].packaging_id
          this.goods_images = data.msg.goods_images
          this.goods_cu_price=data.msg.spec[0].spec_cu_price
        }
      })
    },
    fnGoodsInCar() {
      let params = {
        goods_id: this.data.goods_id,
        spec_number: this.num,
        packaging_id: this.packaging_id,
        spec_id: this.spec_id
      }
      addShopCar(params).then((data) => {
        if (data.status == 200) {
          this.$toast(data.msg)
        }
      })
    },
    fnBuy() {
      this.$router.push({
        name: 'submit',
        params: {
          type: 2,
          goods_id: this.data.goods_id,
          packaging_id: this.packaging_id,
          spec_id: this.spec_id,
          goods_quantity: this.num
        },
      })
    },
    handleChange(value) {
      this.num = value
    }
  }

}
</script>


<style scoped>
.my-swipe .van-swipe-item {
  color: #fff;
  height:66pxpx;
}



.fl {
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  margin-bottom: 8px;
}

.name {
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  line-height: 16px;
  align-items: center;
  margin-bottom: 8px;
}

.price {
  font-weight: 400;
  margin-left: 10px;
  color: #008dd1;
  font-weight: 500;
}

.info {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 20px;
  margin-bottom: 20px;
}

.title {
  font-weight: 500;
  font-size: 16px;
  color: #333333;
}

.title1 {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  margin-top: 8px;
}

.top {
  width: 100%;
  height: 239px;
  overflow: hidden;
  overflow-x: scroll;
}

.top::-webkit-scrollbar {
  height: 0;
}

.top1 {
  width: fit-content;
}

.label {
  height: 28px;
  border-radius: 17px;
  border: 1px solid #e3e3e3;
  padding: 0 22px;
  line-height: 28px;
  margin-right: 12px;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
}

.label.active {
  box-shadow: 0px 0px 4px 0px rgba(1, 142, 207, 0.1);
  border: 1px solid #018ecf;
  color: #008dd1;
}

.bottom {
  z-index: 99;
  padding: 7px 16px;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  align-items: center;
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
}

.btn1 {
  padding: 0 10px;
  height: 30px;
  border-radius: 31px;
  border: 1px solid #018ecf;
  font-weight: 400;
  font-size: 14px;
  color: #018ecf;
  line-height: 30px;
  text-align: center;
  margin-left: 11px;
}

.btn2 {
  padding: 0 10px;
  height: 30px;
  background: #018ecf;
  border-radius: 31px;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  line-height: 30px;
  text-align: center;
  margin-left: 11px;
}

::v-deep .van-stepper {
  background: #ffffff;
  border-radius: 15px;
  border: 1px solid #979797;
}

::v-deep .van-stepper__minus {
  border-radius: 15px 0 0 15px;
}

::v-deep .van-stepper__plus {
  border-radius: 0 15px 15px 0;
}

::v-deep .van-stepper__minus--disabled,
::v-deep .van-stepper__input,
::v-deep .van-stepper__plus,
::v-deep .van-stepper__minus {
  background: #ffffff;
}

input[type="checkbox"] {
  position: absolute;
  top: 10px;
  right: 16px;
  -webkit-appearance: none;
  width: 24px;
  height: 24px;
  border: 2px solid #979797;
  border-radius: 1px;
  margin-right: 16px;
}

input[type="checkbox"]:checked {
  border: 2px solid #018ecf;
  background: url("@/assets/checked.png") no-repeat center center;
  background-size: 18px 18px;
}
.fwb >>> img {
  width: 100%;
  display: block;
}
</style>