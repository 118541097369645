<template>
  <div class="bg1">
    <div class="header before">
      <img :src="require('@/assets/leftArrow.png')" class="leftArrow"  @click="toBack()">
      <div class="font-333-18-500 center" style="line-height: 44px;">{{ $t('jbXX.zhsz') }}</div>
    </div>
    <div style="padding: 20px 16px 0;">
      <div class="align-between" v-if="status==1 || status==3">
        <div class="relative hidden">
          <img :src="require('@/assets/tou.png')" alt="" style="width: 73px;height: 73px;">
          <img :src="require('@/assets/camera.png')" alt="" class="camera">
        </div>
        <div class="flex-con"></div>
        <div class="bg2 align-center font-fff-14">{{ zf }}</div>
      </div>
      <div class="font-333-16-500 m_t12 m_b10">{{ $t('jbXX.jbxx') }}</div>
      <div class="align-between font-333-14 m_b4">
        <div>{{ $t('jbXX.dzyx') }}</div>
        <div class="color-018ECF hidden"  @click="fnChange(1)">{{ $t('jbXX.genggai') }}</div>
      </div>
      <div class="font-666-14 m_b10">{{ email }}</div>
      <div class="align-between font-333-14 m_b4">
        <div>{{ $t('jbXX.xm') }}</div>
        <div class="color-018ECF"  @click="fnChange(0)">{{ $t('jbXX.genggai') }}</div>
      </div>
      <div class="font-666-14 m_b10">{{ user_name }} {{ user_xing }}</div>  
      <div class="align-between font-333-14 m_b4">
        <div>{{ $t('jbXX.gs') }}</div>
        <div class="color-018ECF"   @click="fnChange(2)">{{ $t('jbXX.genggai') }}</div>
      </div>
      <div class="font-666-14 m_b10">{{ user_company }}</div>
      <div class="align-between font-333-14 m_b4">
        <div>{{ $t('jbXX.zw') }}</div>
        <div class="color-018ECF"   @click="fnChange(2)">{{ $t('jbXX.genggai') }}</div>
      </div>
      <div class="font-666-14 m_b10">{{ user_position }}</div>
      <div class="align-between font-333-14">
        <div>{{ $t('jbXX.dh') }}</div>
        <div class="color-018ECF"  @click="fnChange(4)">{{ $t('jbXX.genggai') }}</div>
      </div>
    </div>
    <van-divider :style="{ borderColor: '#eee', margin: '12px 0' }" />
    <div style="padding: 0 16px;">
      <div class="font-333-16-500 m_b4">{{ $t('logon.mm') }}</div>
      <div class="font-018ECF-14"  @click="fnChange(5)">{{ $t('logon.xgdlmm') }}</div>
    </div>
    <div style="width: 100%;padding: 20px;margin-top: 150px;">
      <div class="btn2 align-center font-fff-16" @click="exit" >{{ $t('headGRZXObj.tczh') }}</div>
    </div>
    
    <van-popup v-model="show">
      <div class="dmiddle">
        <div class="dtop flex-wrap">
          <div class="flex-con">{{ $t('logon.dl26') }}</div>
          <img src="../assets/closeee.png" class="closeee"  @click="show=false">
        </div>
        <div class="dtitle flex-wrap">
          <div class="flex-con"></div>
          <img src="../assets/message.png" class="message">
          <div>{{ $t('logon.dl27') }}</div>
          <div class="flex-con"></div>
        </div>
        <div class="dinfo">
          拒绝原因
        </div>
      </div>
    </van-popup>
  
  </div>
</template>

<script>
import { userBaseInfo } from '../api'
export default {
  data() {
    return {
      user_name: '',
      user_xing: '',
      email: '',
      user_phone: '',
      user_company: '',
      user_position: '',
      status: '',
      user_refuse_reason: '',
      show: false,
      zf:'',
    
    }
  },
  methods: {
    initData() {
      userBaseInfo({
        user_id: this.$route.params.user_id,
      }).then((data) => {
        if (data.status == 200) {
          this.user_name = data.msg.user_name;
          this.user_phone = data.msg.user_phone;
          this.user_xing = '';
          this.email = data.msg.user_email;
          this.user_company = data.msg.user_company;
          this.user_position = data.msg.user_position;
          this.user_refuse_reason = data.msg.user_refuse_reason || '';
          this.show = data.msg.user_status == 3 ? true : false;
          this.status = data.msg.user_status;
          if(this.status==1){
            this.zf=this.$t('logon.dl23')
          }
          if(this.status==3){
            this.zf=this.$t('logon.dl27')
          }
          
        }
      })
    },
    fnChange(i) {
      // console.log(i)
      switch (i) {
        case 0:
          this.$router.push({ name: 'changeName', params: { user_name:this.user_name,user_xing:this.user_xing } })
          break
        case 1:
          this.$router.push({ name: 'changeEmail', params: {email:this.email} })
          break
        case 2:
          this.$router.push({ name: 'changeCompany', params: {user_company:this.user_company,user_position:this.user_position} })
          break
        case 3:
          // this.$router.push({ name: 'changeName', params: {} })
          break
        case 4:
          this.$router.push({ name: 'changePhone', params: {user_phone:this.user_phone} })
          break
        case 5:
          this.$router.push({ name: 'changePwd', params: {} })
          break
        default:
          break
      }
    },
    exit() {
      localStorage.setItem('allUserMrh5', '')
      this.$router.push({ name: 'login' })
    },
  },
  mounted() {
    this.initData()
  },

}
</script>


<style scoped>
.camera {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 3px;
}

.bg2 {
  width: 84px;
  height: 22px;
  background: url("@/assets/status_bg1.png") no-repeat center center;
  background-size: 100% 100%;
}
.bg1 {
  width: 100%;
  height: 100vh;
  background: url("@/assets/bg1.png") no-repeat left top;
  background-size: 95% auto;
}
.dmiddle {
  font-size: 14px;
  font-weight: 400;
  color: #333;
  width: 230px;
  background: url("@/assets/bg2.png") no-repeat left top;
  background-size: 100% 100%;
  padding-bottom: 40px;
}
.dtop {
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  height: 44px;
  align-items: center;
  border: 1px solid #96dbfa;
  padding: 0 12px;
}
.closeee {
  width: 20px;
  height: 20px;
}
.message {
  width: 20px;
  height: 20px;
  margin-right: 11px;
}
.dtitle {
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  margin: 14px 0;
}
.dinfo {
  text-align: center;
}
.btn2 {
  height: 40px;
  background: #018ecf;
  border-radius: 27px;
}
</style>