<template>
  <div>
    <div class="header before" >
      <!-- <img :src="require('@/assets/leftArrow.png')" class="leftArrow" @click="toBack()"> -->
      <div class="hearderTitle">{{ $t('headGRZXObj.gwc') }}</div>
    </div>
    <div class="box">
      <div class="del flex-wrap" style="align-items: center;">
        <div class="flex-con"></div>
        <div>{{ $t('carObj.qx') }}</div>
        <input type="checkbox" name="allCheck" v-model="isAllcheck" @change="allCheck()">
      </div>
      <div class="goods flex-wrap" v-for="(item, index) in data" :key="item.sc_id">
        <img :src="item.goods_thumb" class="goodsImg">
        <div class="before flex-con">
          <div class="title  flex-wrap">
            <div class="flex-con aui-ellipsis-2">{{ $i18n.locale == 'en' ? item.goods_name_en : $i18n.locale == 'es' ? item.goods_name_es : item.goods_name_tp }}</div>
            <input type="checkbox" name="checkbox" v-model="item.checked" @change="oneCheck(item)">
          </div>
          <div class="info">{{ item.packaging_name }}</div>
          <div class="info">{{ item.spec_value }}</div>
          <div class="price flex-wrap">
            <div class="flex-con">$ {{ item.spec_cu_price }}</div>
            <van-stepper v-model="item.sc_number" :min="1" :max="stock" @change="changeNums(item.sc_number,item.sc_id)" />
          </div>
        </div>
      </div>
    </div>
    <div class="bottom flex-wrap">
      <div class="flex-con">{{ $t('goodsObj.zj') }}：<span class="price1">${{allPrice}}</span></div>
      <div class="btn1"  @click="fnDelete">{{ $t('carObj.sc') }}</div>
      <div class="btn" @click="fnSubmit">{{ $t('carObj.tj') }}</div>
    </div>
    <van-tabbar  route>
      <van-tabbar-item replace to="/" icon="wap-home-o">{{ $t('xjd.sy') }}</van-tabbar-item>
  <van-tabbar-item replace to="/sortThree/0"  icon="apps-o">{{ $t('xjd.fl') }}</van-tabbar-item>
  <van-tabbar-item replace to="/shoppingCar" icon="shopping-cart-o">{{ $t('xjd.gwc') }}</van-tabbar-item>
  <van-tabbar-item replace to="/mine"  icon="contact-o">{{ $t('xjd.wd') }}</van-tabbar-item>
</van-tabbar>
  </div>
</template>
    
<script>
import { shopCarList, setNumber, delShopCar } from '../api'
export default {
  data() {
    return {
      stock: 10,
      nums: 1,
      data: [],
      sc_ids: '',
      allPrice: 0,
      isAllcheck: false,
      
    }
  },
  methods: {
    fnSubmit() {
      var _this = this;
      _this.ids = '';
      var checkArray = this.data.filter(function (item) {
        return item.checked == true;
      });
      if (checkArray.length == 0) {
        this.$toast(this.$t('new.tip4'))
        return;
      }
      checkArray.forEach(function (item) {
        _this.ids = _this.ids + item.sc_id + ',';
      });
      _this.ids = _this.ids.substring(0, _this.ids.lastIndexOf(','));
      this.$router.push({
        name: 'submit',
        params: {
          type: 1,
          sc_ids:_this.ids
        },
      })
    },
    fnDelete(){
            var _this=this;
            _this.ids='';
            var checkArray = this.data.filter(function (item) {
                return item.checked==true;
            });
            checkArray.forEach(function (item) {
                _this.ids=_this.ids+item.sc_id+',';                                   
            });
            _this.ids=_this.ids.substring(0,_this.ids.lastIndexOf(','));
            delShopCar({
                sc_ids:_this.ids
            }).then((data) => {
            if (data.status == 200) {
                _this.allPrice=0;
                _this.isAllcheck=false;
                _this.initData();
            }
            })
        },
    initData() {
      let params = {}
      shopCarList(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
        }
      })
    },
    oneCheck(item) {
      var checkArray = this.data.filter(function (item) {
        return item.checked == true;
      });
      if (checkArray.length == this.data.length) {
        this.isAllcheck = true;
      } else {
        this.isAllcheck = false;
      }
      this.countPrice();
    },
    allCheck() {
      if (this.isAllcheck == true) {
        this.data.forEach(function (item) {
          item.checked = true;
        });
      } else {
        this.data.forEach(function (item) {
          item.checked = false;
        });
      }
      this.countPrice();
    },
    countPrice() {
      var _this = this;
      var checkArray = this.data.filter(function (item) {
        return item.checked == true;
      });
      this.checkNum = checkArray.length;
      _this.allPrice = 0;
      checkArray.forEach(function (item) {
        _this.allPrice += item.spec_cu_price * item.sc_number;
      });
    },
    changeNums(number, id) {
      let params = {
        number: number,
        sc_id: id,
      }
      setNumber(params).then((data) => {
        if (data.status == 200) {
          this.countPrice();
        }
      })
    },
  },
  mounted() {
    this.initData()
  },

}
</script>
<style scoped>
.box {
  padding: 0 16px 50px;
}
.del {
  padding-top: 16px;
  padding-bottom: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}
.goods {
  padding-bottom: 14px;
  border-bottom: 1px solid #e3e3e3;
}
.goodsImg {
  width: 138px;
  height: 138px;
  margin-right: 10px;
}
.title {
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  line-height: 16px;
  /* align-items: center; */
  margin-top: 5px;
}
.closeee {
  width: 20px;
  height: 20px;
  margin-left: 6px;
}
.info {
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  margin-top: 6px;
}
.price {
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  align-items: center;
  margin-top: 16px;
}
.bottom {
  position: fixed;
  width: 100%;
  bottom: 50px;
  left: 0;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px -2px 4px 0px #e2e2e2;
  align-items: center;
  padding: 0 16px;
}
.price1 {
  font-weight: 500;
  font-size: 16px;
  color: #333;
}
.btn {
  height: 30px;
  background: #018ecf;
  border-radius: 15px;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  line-height: 30px;
  padding: 0 15px;
}
.btn1 {
  height: 30px;
  border: 1px solid #018ecf;
  border-radius: 15px;
  font-weight: 400;
  font-size: 12px;
  color: #018ecf;
  line-height: 30px;
  padding: 0 15px;
  margin-right: 6px;
}
::v-deep .van-stepper {
  background: #ffffff;
  border-radius: 15px;
  border: 1px solid #979797;
}
::v-deep .van-stepper__minus {
  border-radius: 15px 0 0 15px;
}
::v-deep .van-stepper__plus {
  border-radius: 0 15px 15px 0;
}
::v-deep .van-stepper__minus--disabled,
::v-deep .van-stepper__input,
::v-deep .van-stepper__plus,
::v-deep .van-stepper__minus {
  background: #ffffff;
}
input[type="checkbox"] {
  -webkit-appearance: none;
  width: 24px;
  height: 24px;
  border: 2px solid #979797;
  border-radius: 1px;
  margin-left: 10px;
}

input[type="checkbox"]:checked {
  border: 2px solid #018ecf;
  background: url("@/assets/checked.png") no-repeat center center;
  background-size: 18px 18px;
}
</style>