<template>
  <div>
    <van-sticky>
      <div class="header before" style="background:linear-gradient(to bottom, #68C3ED, #A5E2FF) ;">
        <img :src="require('@/assets/leftArrow.png')" class="leftArrow" @click="toBack()">
        <img :src="require('@/assets/logo.png')" class="headerlogo">
      </div>
      <!-- <div class="searchAll">
        <div class="searchBox flex-wrap">
          <input type="text" :placeholder="$t('headSSCP')" class="flex-con" v-model="keyword">
          <img :src="require('@/assets/search.png')" class="search" @click="fnSearch">
        </div>
      </div> -->
      <div class="searchAll">
      <div class="searchBox flex-wrap">
       
        <img :src="require('@/assets/search.png')" class="search" @click="fnSearch" style="margin-left: 9px;">
        <input type="text" :placeholder="$t('headSSCP')" class="flex-con" style="background-color: #F8F8F8;border-right: 1px solid #008DD1;" v-model="keyword">
        <div style="font-size: 12px;color: #008DD1;padding: 10px;" @click="fnSearch">{{ $t('xjd.ss') }}</div>
      </div>
    </div>
    </van-sticky>
    <van-empty style="margin: auto" :description="$t('new.no1')" v-if="goods.length == 0" />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" finished-text="" @load="onLoad">
        <div class="box" style="display: flex;flex-wrap: wrap;padding: 10px 16px 0 16px;gap:11px;justify-content: flex-start; ">
          <div class="goods" @click="fnGoodsDetails(item.goods_id)" v-for="(item, index) in goods"
            :key="'goodsList_' + item.goods_id + '_' + index">
            <img :src="require('@/assets/static/p4.png')" class="goodsImg">
            <div class="title aui-ellipsis-2"> {{ $i18n.locale == 'en' ? item.goods_name_en
          : $i18n.locale == 'es' ? item.goods_name_es : item.goods_name_tp }}</div>
            
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { goodsList } from '../api'
export default {
  data() {
    return {
      page: 1,
      count: 0,
      loading: true,
      finished: false,
      refreshing: false,
      keyword: this.$route.query.keyword,
      goods: []
    }
  },
  methods: {
    fnSearch(){
      this.page=1
      this.initGoodsList()
    },
    initGoodsList() {
      let params = {
        page: this.page,
        keyword: this.keyword,
      }
      goodsList(params).then((data) => {
        if (data.status == 200) {
          this.count = data.count
          if (this.refreshing) {
            this.goods = data.msg
            this.refreshing = false
            if (this.goods.length >= this.count) {
              this.finished = true
            }
            return
          }
          this.goods = this.goods.concat(data.msg)
          this.loading = false
          if (this.goods.length >= this.count) {
            this.finished = true
          }
        }
      })
    },
    onLoad() {
      this.page++
      this.initGoodsList()
    },
    onRefresh() {
      this.finished = false
      this.loading = true
      this.page = 1
      this.initGoodsList()
    }
  },
  mounted() {
    this.initGoodsList()
  },

}
</script>


<style scoped>
.searchAll {
  padding: 10px 16px 10px 16px;
}

.searchBox {
  height: 36px;
  background: #F8F8F8;
  /* box-shadow: 0px 0px 10px 0px rgba(1, 142, 207, 0.1); */
  border-radius: 18px;
  align-items: center;
  padding-right: 9px;
  border: 1px solid #008DD1;
}

input[type="text"] {
  background: #F8F8F8;
  border: none;
  outline: none;
  padding: 0 12px;
  color: 333;
  font-size: 12px;
}

input[type="text"]::-webkit-input-placeholder {
  font-size: 12px;
  font-weight: 400;
  color: #999;
}

.search {
  width: 17px;
  height: 17px;
}

.box {
  padding: 0 16px;
}

.goods {
  display: inline-block;
  width:104px;
  
  border-radius: 4px;
  
  margin-bottom: 10px;
}



.goodsImg {
  width: 100%;
  height: 104px;
  margin-bottom: 10px;
}

.title {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 16px;
  margin-bottom: 6px;
}

.price {
  font-weight: 500;
  font-size: 16px;
  color: #333333;
}
</style>