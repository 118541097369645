<template>
  <div class="bg1">
    <div class="headerr before">
      <img :src="require('@/assets/closeee.png')" class="leftArrow" @click="onClickLeft" style="top: 40px;">
      <img :src="require('@/assets/logo.png')" class="headerlogo">
    </div>
    <div style="padding:10px 16px 0;border-radius: 20px 20px 0 0 ;margin-top: -20px;background-color: #ffffff;">
      <!-- <div class="font-018ECF-16 m_b14">{{ $t('logon.dl8') }}</div> -->
      <div style="margin-top: 30px;font-size: 14px;color: #666666;">
        {{ $t('logon.dl19') }}
      </div>
      <input type="text" v-model="email" class="m_b10" :placeholder="$t('logon.dl19')" value="">
      <div style="font-size: 14px;color: #666666;">
        {{ $t('logon.dl9') }}
      </div>
      <div class="one flex-wrap m_b10">
        <input type="text" v-model="sms" class="flex-con border_none" style="margin-top: 0;" :placeholder="$t('logon.dl9')" value="">
        <div class="getCode" @click="need_ma()">{{ yzm }}</div>
      </div>
      <div style="font-size: 14px;color: #666666;">
        {{ $t('logon.dl6') }}
      </div>
      <input type="text" v-model="pwd" class="m_b10" :placeholder="$t('logon.dl6')" value="">
      <div style="font-size: 14px;color: #666666;">
        {{ $t('logon.dl10') }}
      </div>
      <input type="text" v-model="pwd1" :placeholder="$t('logon.dl10')" value="">
      <div class="font-018ECF-12 m_t6 hidden">This email already has an account - log in to continue.</div>
      <div class="font-999-16 m_t30" style="font-size: 12px;">{{ $t('logon.agree') }}<span class="color-018ECF" @click="fnAbout(2)"> {{ $t('footAll.yszc')
                    }}</span> & <span class="color-018ECF" @click="fnAbout(1)">{{ $t('footAll.sjbhzc')
                    }}</span></div>
      <div class="btn align-center font-fff-16 m_t14"  @click="fnOver">{{ $t('logon.dl8') }}</div>
    </div>
  </div>
</template>

<script>
import { sendCode, emailRegister } from '../api'
export default {
  data() {
    return {
      email: '',
      sms: '',
      yzm: this.$t('logon.dl11'),
      isOnclick: true,
      onClickTime: '',
      isinerval: '',
      pwd: '',
      pwd1: '',
    }
  },
  methods: {
    fnOver() {
      if (this.email == '') {
        this.$toast(this.$t('logon.dl5'))
        return;
      }
      if (this.sms == '') {
        this.$toast(this.$t('logon.dl9'));
        return;
      }
      if (!/^\d{6}$/.test(this.sms)) {
        this.$toast(this.$t('logon.dl12'));
        return;
      }
      if (!this.pwd) {
        this.$toast(this.$t('logon.dl6'));
        return;
      }
      if (this.pwd != this.pwd1) {
        this.$toast(this.$t('logon.dl13'));
        return;
      }
      emailRegister({
        email: this.email,
        code: this.sms,
        pwd: this.pwd,
        verifyPwd: this.pwd1,
      }).then((data) => {
        if (data.status == 200) {
          this.$router.push({
            name: 'completeData',
            params: { type: 2, email: data.user.user_email, user_id: data.user.user_id  }
          })
        }
      })

    },
    need_ma() {
      if (this.email == '') {
        this.$toast(this.$t('logon.dl5'))
        return;
      }
      if (this.isOnclick) {
        //调用接口判断
        sendCode({
          email: this.email,
          type: 1
        }).then((data) => {
          if (data.status == 200) {
             this.$toast(this.$t('logon.dl14'))
            if (this.isOnclick) {
              this.onClickTime = Math.floor((new Date().getTime()) / 1000)
              this.isinerval = setInterval(this.CountDown, 1000);
              this.isOnclick = false;
            }
          }
        })
      }
    },
    CountDown() {
      this.onClickTime1 = 60 - (Math.floor((new Date().getTime()) / 1000) - this.onClickTime);
      if (this.onClickTime1 < 1) {
        this.yzm = this.$t('logon.dl15');
        clearInterval(this.isinerval);
        this.isOnclick = true;
        return;
      }
      this.yzm = this.onClickTime1 + 's';
    },
    fnAbout(type) {
      this.$router.push({ name: 'aboutUs', params: {type:type} })
    },
    onClickLeft() {
      this.$router.go(-1)
    },
  },
  mounted() {
   
  },

}
</script>


<style scoped>
.bg1 {
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
}
.getCode {
  line-height: 40px;
  font-size: 12px;
  font-weight: 400;
  color: #018ecf;
  padding-right: 12px;
}

.one {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #979797;
  margin-top: 10px;
}

input[type="text"],
input[type="password"],
input[type="tel"] {
  background: rgba(0, 0, 0, 0);
  height: 40px;
  border-radius: 10px;
  border: 1px solid #979797;
  box-sizing: border-box;
  padding: 0 12px;
  color: #333;
  font-size: 16px;
  outline: none;
  display: block;
  width: -webkit-fill-available;
  margin-top: 10px;
}

input[type="text"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder {
  color: #999;
}

.btn {
  height: 40px;
  background:linear-gradient(to bottom, #A6E3FF, #018ECF);
  border-radius: 27px;
}
.headerr{
    height: 200px;
    align-items: center;
    padding: 0 16px;
    
    line-height: 44px;
    background-image: url(@/assets/bg.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.headerlogo{
  display: block;
    width: 128px;
    height: 30px;
    margin:40px auto;
}
</style>