<template>
  <div>
    <div style="width: 100%;height: 247px;background:linear-gradient(to bottom, #048FD0, #FFFFFF) ;display: flex;justify-content: right;">
      <img :src="require('@/assets/fang.png')" alt="" style="width: 195px;height: 180px;">
    </div>
    <div id="inquirySheet" class="flex-wrap flex-vertical bg1" style="padding:0 16px 12px;margin-top: -240px;z-index: 1;">
    <div class="flex-con">
      <div class="header before" style="padding: 0;margin-top: 30px;">
        <img :src="require('@/assets/leftwhite.png')" class="leftArrow" style="left: 0;"  @click="toBack()">
        <div class="font-333-18-500 center" style="line-height: 44px;color: #ffffff;">{{$t('xjd.txxjd')}}</div>
      </div>
      <div style="padding:12px 0;box-sizing: border-box;">
        <div style="margin-bottom: 8px;font-size: 14px;">{{ $t('xjd.cggsmc') }}</div>
        <div class="input_out ">
          <input type="text" v-model=" order_xjd_gs_name" :placeholder="$t('xjd.cggsmc')">
        </div>
        <div style="margin-bottom: 8px;font-size: 14px;">{{ $t('xjd.cggsdz') }}</div>
        <div class="input_out ">
          <input type="text" v-model="order_xjd_gs_address" :placeholder="$t('xjd.cggsdz')">
        </div>
        <div style="margin-bottom: 8px;font-size: 14px;">{{ $t('xjd.cgyxm') }}</div>
        <div class="input_out ">
          <input type="text" v-model="order_xjd_user_name" :placeholder="$t('xjd.cgyxm')">
        </div>
        <div style="margin-bottom: 8px;font-size: 14px;">{{ $t('xjd.cgydh') }}</div>
        <div class="input_out ">
          <input type="text" v-model="order_xjd_user_phone" :placeholder="$t('xjd.cgydh')">
        </div>
        <div style="margin-bottom: 8px;font-size: 14px;">{{ $t('xjd.yjdz') }}</div>
        <div class="input_out ">
          <input type="text" v-model="order_xjd_user_email" :placeholder="$t('xjd.yjdz')">
        </div>
        <div style="margin-bottom: 8px;font-size: 14px;">{{ $t('xjd.cyg') }}</div>
        <div class="input_out ">
          <input type="text" v-model="order_xjd_cyg" :placeholder="$t('xjd.cyg')">
        </div>
        <div style="margin-bottom: 8px;font-size: 14px;">{{ $t('xjd.mdg') }}</div>
        <div class="input_out ">
          <input type="text" v-model="order_xjd_mdg" :placeholder="$t('xjd.mdg')">
        </div>
        <div style="margin-bottom: 8px;font-size: 14px;">{{ $t('xjd.ddss') }}</div>
        <div class="input_out ">
          <input type="text" v-model="order_xjd_gettime" :placeholder="$t('xjd.ddss')">
        </div>
        <div style="margin-bottom: 8px;font-size: 14px;">{{ $t('xjd.mysysj') }}</div>
        <div class="input_out ">
          <input type="text" v-model="order_xjd_mysysj" :placeholder="$t('xjd.mysysj')">
        </div>
        <div style="margin-bottom: 8px;font-size: 14px;">{{ $t('xjd.ysfs') }}</div>
        <div class="input_out ">
          <input type="text" v-model=" order_xjd_paytype" :placeholder="$t('xjd.ysfs')">
        </div>
        <div class="title">
          {{ $t('xjd.xjcp') }}
        </div>
        
     <div >
     <div style="box-sizing: border-box;padding: 12px;box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);border-radius: 4px;margin-top: 15px;">
      <select  @change="handleSelectChange" style="margin-top: 12px;" class="sel"  v-model="selected" :placeholder="$t('xjd.cgood')" id="aa" >
        <option value="" disabled selected>{{$t('xjd.cgood')}}</option>
    <option
      v-for="option in dataa"
      :key="option.goods_id"
      :label=" $i18n.locale == 'en' ? option.goods_name_en
      : $i18n.locale == 'es' ? option.goods_name_es
        : option.goods_name_tp "
      :value=" option.goods_id ">
    </option>
  </select>
            
       
        <div class=" half"  style=" margin-top: 16px;" >
          <select id="bb"   class="sel"  v-model="selected1" :placeholder="$t('xjd.xzgg')">
            <option value="" disabled selected>{{$t('xjd.xzgg')}}</option>
    <option
      v-for="option1 in datac"
      :key="option1.spec_id"
      :label="option1.spec_value"
      :value="option1.spec_id">
    </option>
  </select>
     
        </div>
        <div class=" half"  style="margin-top: 16px;">
          <input type="tel" id="shu" v-model="num"  :placeholder="$t('xjd.txsl')">
          <select id="cc" style="width: 48%;" class="sel"  v-model="selected2" :placeholder="$t('xjd.xzbz')">
            <option value="" disabled selected>{{$t('xjd.xzbz')}}</option>
    <option
      v-for="option2 in datab"
      :key="option2.packaging_id"
      :label="option2.packaging_name"
      :value="option2.packaging_id">
    </option>
  </select>
              
         
     </div>
     <div class=" half"  style="margin-top: 16px;">
          <input type="tel" id="shu" v-model="dw"  :placeholder="$t('xjd.dw')">
          <select id="ee" style="width: 48%;" class="sel"  v-model="selected4" :placeholder="$t('xjd.xzhb')">
            <option value="" disabled selected>{{$t('xjd.xzhb')}}</option>
    <option
      v-for="(option3) in options"
      :key="option3"
      :label="option3"
      :value="option3">
    </option>
  </select>
              
         
     </div>
     <div class="add_ant"  @click="addNew()">
      <img src="../assets/add.png" alt="" style="width: 20px;height: 20px;margin-right: 12px;margin-top: 10px;">
            <div>  {{ $t('jbXX.tj') }}</div>
     </div>
     </div>
    <div  >
      <div v-for="(item, index) in list" :key="index">
     <div style="box-sizing: border-box;padding: 0 12px 0 12px;box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);border-radius: 4px;margin-top: 15px;overflow: hidden;">
      <div style="display: flex;">
      
      <div class="sel2" style="margin-top: 12px;" >{{ item.goods_id || '' }}</div>
    <img src="../assets/reduce.png" @click="handleDelete(index)" alt="" style="width: 20px;height: 20px;margin-left: 9px;margin-top: 22px;"></div>
        
     <div class=" half"  style=" margin-top: 16px;display: flex;" >
         
       <div class="sel2">{{ item.spec_id || '' }}</div>
       <!-- <div class="sel1">{{ item.packaging_id || '' }}</div> -->
           </div>
           <div class=" half"  style="margin-top: 16px;display: flex;">
       <div class="sel1">{{ item.num || '' }}</div>
       <div class="sel1">{{ item.packaging_id || '' }}</div>
        </div>
           <div class=" half"  style="margin-top: 16px;display: flex;margin-bottom: 12px;">
       <div class="sel1">{{ item.of_danwei || '' }}</div>
       <div class="sel1">{{ item.moneytype || '' }}</div>
        </div>
     </div>
    
  </div>
    </div>
     
     
     
        </div>
        
      </div>
    
  
      
    </div>
    <div class="btn2 align-center font-fff-16" @click="initCommit()">{{ $t('logon.dl25') }}</div>
  </div>
  </div>
</template>

<script>
import {  getLikeGoods, goodsDetail ,getXunOrder, } from '../api'
export default {
  data() {
    return {
      show: true,
      page: 1,
     
      total: 0,
      data: [],
      xun_username: '',
      num: '',
      xjcp: '',
      selected: '',
      dataa: [],
      datab: [],
      datac: [],
      selected1: '',
      selected2: '',
      options: ['USD', 'EUR'],
      selected4: '',
      order_xjd_myg: '',
      order_xjd_cyg: '',
      order_xjd_mdg: '',
      order_xjd_ysfs: '',
      order_xjd_mysysj: '',
      order_xjd_gs_name:'',
      order_xjd_gs_address:'',
      order_xjd_user_name:'',
      order_xjd_user_phone:'',
      order_xjd_user_email:'',
      order_xjd_gettime:'',
      order_xjd_paytype:'',
      dw:'',
      list:[],
      list1:[]
    }
  },
  methods: {
    initCommit() {
      if (!this.order_xjd_cyg) {
        this.$message.error(this.$t('xjd.cyg'))
        return;
      }
      if (!this.order_xjd_mdg) {
        this.$message.error(this.$t('xjd.mdg'))
        return;
      }
      if (!this.order_xjd_gs_name) {
        this.$message.error(this.$t('xjd.cggsmc'))
        return;
      }
      if (!this.order_xjd_gs_address) {
        this.$message.error(this.$t('xjd.cggsdz'))
        return;
      }
      if (!this.order_xjd_user_name) {
        this.$message.error(this. $t('xjd.cgyxm'))
        return;
      }
      if (!this.order_xjd_user_phone) {
        this.$message.error(this.$t('xjd.cgydh'))
        return;
      }
      if (!this.order_xjd_user_email) {
        this.$message.error(this.$t('xjd.yjdz'))
        return;
      }
      if (!this.order_xjd_gettime) {
        this.$message.error(this.$t('xjd.ddss'))
        return;
      }
      if (!this.order_xjd_paytype) {
        this.$message.error(this.$t('xjd.ysfs'))
        return;
      }
      
      if (!this.order_xjd_mysysj) {
        this.$message.error(this.$t('xjd.mysysj'))
        return;
      }
      if (!this.list.length) {
        this.$message.error(this.$t('xjd.cpsl'))
        return;
      }
      getXunOrder({
        order_xjd_myg: '',
        order_xjd_cyg: this.order_xjd_cyg,
        order_xjd_mdg: this.order_xjd_mdg,
        order_xjd_ysfs: '',
        order_xjd_mysysj: this.order_xjd_mysysj,
        order_xjd_gs_name:this.order_xjd_gs_name,
        order_xjd_gs_address:this.order_xjd_gs_address,
        order_xjd_user_name:this.order_xjd_user_name,
        order_xjd_user_phone:this.order_xjd_user_phone,
        order_xjd_user_email:this.order_xjd_user_email,
        order_xjd_gettime:this.order_xjd_gettime,
        order_xjd_paytype:this.order_xjd_paytype,
        jsoninfo: JSON.stringify(this.list1),
        
      }).then((data) => {
        if (data.status == 200) {
          this.$message.success(data.msg)
          setTimeout(() => {
            this.show = true
            this.page = 1
            this.$router.push({ name: 'inquiryList', params: {} })
          }, 800);
        }
      })
    },
    addNewList(){

    },
    addNew(){
      if (!this.selected) {
        this.$message.error(this.$t('xjd.cgood'))
        return;
      }
      if (!this.selected1) {
        this.$message.error(this.$t('goodsObj.xzgg'))
        return;
      }
      if (!this.selected2) {
        this.$message.error(this.$t('xjd.xzbz'))
        return;
      }
      if (!this.num) {
        this.$message.error(this.$t('xjd.txsl'))
        return;
      }
      if (!this.dw) {
        this.$message.error(this.$t('xjd.txdw'))
        return;
      }
      if (!this.selected4) {
        this.$message.error(this.$t('xjd.xzhb'))
        return;
      }
      const arr1 ={goods_id:this.selected,
        spec_id:this.selected1,
        packaging_id:this.selected2,
        num:this.num,
        moneytyoe:this.selected4,
        of_danwei:this.dw
      }
      var obj = document.getElementById('aa');
     
      var obj1 = document.getElementById('bb');

      var obj2 = document.getElementById('cc');
      var index = obj.selectedIndex ;
      var index1 = obj1.selectedIndex ;
      var index2 = obj2.selectedIndex ;
      var text = obj.options[index].label;
      
      var text1 = obj1.options[index1].label;
      console.log(obj2.options[index2]);
      var text2 = obj2.options[index2].label;
      const arr ={goods_id:text,
        spec_id:text1,
        packaging_id:text2,
        num:this.num,
        moneytype:this.selected4,
        of_danwei:this.dw
      }
      
      
    
     
      
      this.list1.push(arr1)
      console.log(arr);
      // let arr = Array.of('goods_id'+':'+this.selected, 'spec_id'+':'+this.selected1, 'packaging_id'+':'+this.selected2, 'num'+':'+this.num, 'moneytype'+':'+this.selected4);
      this.list.push(arr);
      console.log(JSON.stringify(this.list));
      document.getElementById('aa').selectedIndex = -1;
      document.getElementById('aa').value = '';
      
      
      this.num = '';
      this.dw = '' ;
      this.selected2 = '';
      this.selected1 = '';
      
      
    },
    handleDelete(row){
      this.$confirm(this.$t('xjd.tsxx'),this.$t('xjd.tishi'), {
    confirmButtonText: this.$t('xjd.cg'),
    cancelButtonText: this.$t('xjd.qx'),
    type: "warning"
  })
    .then(() => {
      this.list.splice(row, 1);
       
      
      
      this.$message({
        type: "success",
        message:  this.$t('xjd.sccg')
      });
    })
    .catch(() => {
      this.$message({
        type: "info",
        message: this.$t('xjd.qxsc')
      });
    });

    },
    handleSelectChange(value) {
      console.log(value);
      let params = {
        goods_id: value.target.value,

      }
      goodsDetail(params).then((data) => {
        if (data.status == 200) {
          this.datab = data.msg.goods_packaging;
          this.datac = data.msg.spec
          console.log(data.msg);

        }
      })
    },
    getGoods(){
      getLikeGoods({

}).then((data) => {
  if (data.status == 200) {
    this.dataa = data.msg;


  }
})
    },
  },
  mounted() {
    this.getGoods()
  },

}
</script>


<style scoped>
::v-deep .el-input__inner {
       color: #000000;
       font-size: 14px;
       height: 54px;
      }

     
  .el-select-dropdown__item{
   width: 300px;
        }


.bg1 {
  width: 100%;
 
 
  background-size: 95% auto;
}
.input_out {
  border-radius: 4px;
  border: 1px solid #E1E1E1;
  box-sizing: border-box;
  padding: 0 12px;
  margin-bottom: 13px;
  background-color: #ffffff;
  
}



input[type="text"],
input[type="password"],
input[type="tel"] {
  background: rgba(0, 0, 0, 0);
  height: 40px;
  border-radius: none;
  border: none;
  box-sizing: border-box;
  padding: 0;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  line-height: 40px;
  outline: none;
  display: block;
  width: -webkit-fill-available;
  
}

input[type="text"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder {
  color: #999;
}

.btn2 {
  height: 40px;
  background: #018ecf;
  border-radius: 27px;
}
textarea {
  width: 100%;
  height: 159px;
  border: none;
  padding: 16px 0;
  font-size: 14px;
  color: #333;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0);
  outline: none;
  margin: 0;
  font-weight: 400;
}

textarea::-webkit-input-placeholder {
  color: #999;
}
.title{
  color: #333333;
  font-size: 16px;
  margin-top: 16px;
}
.sel{
  border-radius: 10px;
  border: 1px solid #979797;
  box-sizing: border-box;
  padding: 0 12px;
  background: rgba(0, 0, 0, 0);
  height: 40px;
  
  box-sizing: border-box;
  padding: 0 0 0 12px;
  font-weight: 400;
  font-size: 16px;
  color: #999999;
  line-height: 40px;
  outline: none;
  display: block;
  width: -webkit-fill-available;
  margin-top: 32rpx;
}
.sel1{
  border-radius: 10px;
  border: 1px solid #979797;
  box-sizing: border-box;
  padding: 0 12px;
  background: rgba(0, 0, 0, 0);
  height: 40px;
  border-radius: none;
  
  box-sizing: border-box;
  
  font-weight: 400;
  font-size: 16px;
  color: #999999;
  line-height: 40px;
  
  display: block;
  width:48%;
  margin-top: 32rpx;
}
.sel2{
  border-radius: 10px;
  border: 1px solid #979797;
  box-sizing: border-box;
  padding: 0 12px;
  background: rgba(0, 0, 0, 0);
  height: 40px;
  border-radius: none;
  white-space:nowrap;
   overflow:hidden;
   text-overflow:ellipsis;
  box-sizing: border-box;
  
  font-weight: 400;
  font-size: 16px;
  color: #999999;
  line-height: 40px;
  
  display: block;
  width:100%;
  margin-top: 32rpx;
}
#shu{
  border-radius: 4 px;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  padding: 0 12px;
  background: rgba(0, 0, 0, 0);
  height: 40px;
  border-radius: none;
  
  box-sizing: border-box;
  
  font-weight: 400;
  font-size: 16px;
  color: #999999;
  line-height: 40px;
  
  display: block;
  width:48%;
  
}
.half{
  display: flex;
  justify-content: space-between;
  
}
.add_ant{
  width: 100%;
  height: 40px;
  border-radius: 32px;
  border: 1px solid #018ECF;
  margin-top: 12px;
  text-align: center;
  line-height: 40px;
  color: #018ECF;
  display: flex;
  justify-content: center;
}
</style>