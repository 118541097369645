<template>
    <div style="  background-color: #F5F5F5;overflow: hidden;">
      <div style="width: 100%;text-align: center;height: 88px;line-height: 88px;background: linear-gradient(to bottom, #C2DFED, #F5F5F5);"> {{ $t('xjd.wd') }}</div>
      <div class="bg1">
      
      <div style="width: 100%;height: 65px;display: flex;justify-content: space-between;">
      <div class="pic">
        <img  :src="user_img" alt="" style="width: 100%;height: 100%;">
      </div>
      <div style="margin-top: 12px;flex: 1;margin-left: 20px;"@click="fnMy">
        <p >{{ user_name }} {{ user_xing }}</p>
        <p style="margin-top: 8px;">{{ email }}</p>
      </div>
      <img :src="require('@/assets/leftb.png')" alt="" style="width: 18px;height: 18px;margin-top: 24px;">

      </div>
      <div class="mid">
        <div style="width: 100%;display: flex;justify-content: space-between;"@click="fnOrder(0)">
        <div>{{ $t('headGRZXObj.qbdd') }}</div>
        <img :src="require('@/assets/lefts.png')" alt="" style="width: 18px;height: 18px;">
        </div>
        <div style="width: 100%;display: flex;justify-content: space-between;margin-top: 14px;">
          <div style="text-align: center;flex: 1;" @click="fnOrder(1)">
            <img :src="require('@/assets/o1.png')" alt="" style="width: 28px;height: 28px;">
            <p style="margin-top: 8px;">{{ $t('headGRZXObj.dqr') }}</p>
          </div>
          <div style="text-align: center;flex: 1;" @click="fnOrder(2)">
            <img :src="require('@/assets/o2.png')" alt="" style="width: 28px;height: 28px;">
            <p style="margin-top: 8px;">{{ $t('headGRZXObj.dfk') }}</p>
          </div>
          <div style="text-align: center;flex: 1;" @click="fnOrder(3)">
            <img :src="require('@/assets/o3.png')" alt="" style="width: 28px;height: 28px;">
            <p style="margin-top: 8px;">{{ $t('headGRZXObj.dfh') }}</p>
          </div>
          <div style="text-align: center;flex: 1;" @click="fnOrder(4)">
            <img :src="require('@/assets/o4.png')" alt="" style="width: 28px;height: 28px;">
            <p style="margin-top: 8px;">{{ $t('headGRZXObj.dsh') }}</p>
          </div>
          <div style="text-align: center;flex: 1;" @click="fnOrder(5)">
            <img :src="require('@/assets/o5.png')" alt="" style="width: 28px;height: 28px;">
            <p style="margin-top: 8px;">{{ $t('headGRZXObj.ywc') }}</p>
          </div>

        </div>
       

      </div>
      <div class="mid1">
        <div class="title">
          {{ $t('xjd.xjd') }}
        </div>
        <div class="list" style="margin-top: 9px;">
          <img :src="require('@/assets/list1.png')" alt="" style="width: 24px;height: 24px;margin-left: 12px;margin-top: 14px;">
          <div style="flex: 1;margin-left: 14px;"@click="fnList">
            {{ $t('xjd.xjdlb') }}
          </div>
          <img :src="require('@/assets/lefts.png')" alt="" style="width: 18px;height: 18px;margin-right: 12px;margin-top: 18px;">

        </div>
        <div class="list1" >
          <img :src="require('@/assets/list2.png')" alt="" style="width: 24px;height: 24px;margin-left: 12px;margin-top: 14px;">
          <div style="flex: 1;margin-left: 14px;"@click="fnSheet">
            {{ $t('xjd.txxjd') }}
          </div>
          <img :src="require('@/assets/lefts.png')" alt="" style="width: 18px;height: 18px;margin-right: 12px;margin-top: 18px;">

        </div>

</div>
<div class="mid2">
  <div class="title">
    {{ $t('xjd.cygn') }}
        </div>
        <div class="list" style="margin-top: 9px;">
          <img :src="require('@/assets/list3.png')" alt="" style="width: 24px;height: 24px;margin-left: 12px;margin-top: 14px;">
          <div style="flex: 1;margin-left: 14px;"@click="fnAddress">
            {{ $t('headGRZXObj.dzgl') }}
          </div>
          <img :src="require('@/assets/lefts.png')" alt="" style="width: 18px;height: 18px;margin-right: 12px;margin-top: 18px;">

        </div>
        <div class="list" style="margin-top: 9px;">
          <img :src="require('@/assets/list4.png')" alt="" style="width: 24px;height: 24px;margin-left: 12px;margin-top: 14px;">
          <div style="flex: 1;margin-left: 14px;"@click="fnCollect">
            {{ $t('headGRZXObj.wdsc') }}
          </div>
          <img :src="require('@/assets/lefts.png')" alt="" style="width: 18px;height: 18px;margin-right: 12px;margin-top: 18px;">

        </div>
        <div class="list" style="margin-top: 9px;">
          <img :src="require('@/assets/list5.png')" alt="" style="width: 24px;height: 24px;margin-left: 12px;margin-top: 14px;">
          <div style="flex: 1;margin-left: 14px;"@click="fnAbout(2)">
            {{ $t('headYSZC') }}
          </div>
          <img :src="require('@/assets/lefts.png')" alt="" style="width: 18px;height: 18px;margin-right: 12px;margin-top: 18px;">

        </div>
        <div class="list" style="margin-top: 9px;">
          <img :src="require('@/assets/list6.png')" alt="" style="width: 24px;height: 24px;margin-left: 12px;margin-top: 14px;">
          <div style="flex: 1;margin-left: 14px;"@click="fnAbout(7)">
            {{ $t('headGYWM') }}
          </div>
          <img :src="require('@/assets/lefts.png')" alt="" style="width: 18px;height: 18px;margin-right: 12px;margin-top: 18px;">

        </div>
        <div class="list1" >
          <img :src="require('@/assets/list7.png')" alt="" style="width: 24px;height: 24px;margin-left: 12px;margin-top: 14px;">
          <div style="flex: 1;margin-left: 14px;"@click="fnAbout(4)">
            {{ $t('headLXWM') }}
          </div>
          <img :src="require('@/assets/lefts.png')" alt="" style="width: 18px;height: 18px;margin-right: 12px;margin-top: 18px;">

        </div>
</div>
    
      <van-tabbar   route>
        <van-tabbar-item replace to="/" icon="wap-home-o">{{ $t('xjd.sy') }}</van-tabbar-item>
  <van-tabbar-item replace to="/sortThree/0"  icon="apps-o">{{ $t('xjd.fl') }}</van-tabbar-item>
  <van-tabbar-item replace to="/shoppingCar" icon="shopping-cart-o">{{ $t('xjd.gwc') }}</van-tabbar-item>
  <van-tabbar-item replace to="/mine"  icon="contact-o">{{ $t('xjd.wd') }}</van-tabbar-item>
  </van-tabbar>
    </div>
    </div>
  </template>
  
  <script>
  import { userBaseInfo } from '../api';
  export default {
    data() {
      return {
      user_name: '',
      user_xing: '',
      email: '',
      user_img:'',
      
      }
    },
    methods: {
      initData() {
      userBaseInfo({
        
      }).then((data) => {
        if (data.status == 200) {
          console.log(data);
          

          this.user_name = data.msg.user_name;
          this.user_xing ='';
          this.email = data.msg.user_email;
          this.user_img = data.msg.user_image;
          console.log(data.msg.user_image);
          console.log(this.user_img);
          
          
        }
      })
    },
    fnAddress() {
      this.$router.push({ name: 'addressList', params: {} })
    },
    fnCollect() {
      this.$router.push({ name: 'collect', params: {} })
    },
    fnAbout(type) {
      this.$router.push({ name: 'aboutUs', params: {type:type} })
    },
    fnList() {
      this.$router.push({ name: 'inquiryList', params: {} })
    },
    fnSheet() {
      this.$router.push({ name: 'inquirySheet', params: {} })
    },
    fnOrder(status) {
      this.$router.push({ name: 'order', params: {status:status} })
    },
    fnMy(){
      this.$router.push({ name: 'accountSetting', params: {} })
    },
    },
    mounted() {
      this.initData();
    },
  
  }
  </script>
  
  
  <style scoped>
 
  .bg1 {
    width: 100%;
    
    background-color: #F5F5F5;
  
    background-size: 95% auto;
    overflow: hidden;
    box-sizing: border-box;
    padding: 16px 16px 100px 16px;
  }
  .pic{
    width: 65px;
    height: 65px;
    border-radius: 50%;
   overflow: hidden;
  }
  .mid{width: 100%;
    height: 130px;
    border-radius: 4px;
    background-color: #ffffff;
    margin-top: 30px;
    box-sizing: border-box;
    padding: 14px 12px 14px 12px;


  }
  .mid1{
    width: 100%;
   
    border-radius: 4px;
    background-color: #ffffff;
    margin-top: 12px;
    overflow: hidden;
  
   
  }
  .mid2{
    width: 100%;
   
    border-radius: 4px;
    background-color: #ffffff;
    margin-top: 12px;
    overflow: hidden;
  
   
  }
  .title{
    font-size: 16px;
    color: #333333;
    margin-top: 9px;
    margin-left: 12px;
  }
  .list{
    width: 100%;
    height: 55px;
    border-bottom: 1px solid #E6E6E6;
    display: flex;
    justify-content: space-between;
    line-height: 55px;
  }
  .list1{
    width: 100%;
    height: 55px;
    
    display: flex;
    justify-content: space-between;
    line-height: 55px;
  }
  </style>