import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import VueI18n from 'vue-i18n'
import English from './assets/i18/en.js'
import Spanish from './assets/i18/spa.js'
import Portuguese from './assets/i18/por.js'
Vue.prototype.$bus = new Vue()
Vue.config.productionTip = false
Vue.use(VueI18n);
Vue.use(ElementUI);
const i18n = new VueI18n({
  locale: localStorage.getItem('lang')||'English',
  messages:{
    English:English,
    Español:Spanish,
    Português:Portuguese
  }
})
import {
  Cell,
  CellGroup,
  Tabbar,
  TabbarItem,
  NavBar,
  Cascader, 
  Popup,
  ImagePreview,
  Overlay,
  Picker,
  Uploader,
  Sticky,
  Tab,
  Tabs,
  List,
  PullRefresh,
  Loading,
  Toast,
  Empty,
  DropdownMenu, 
  DropdownItem,
  Stepper,
  Divider,
  Swipe, 
  SwipeItem
} from 'vant'

Vue.config.productionTip = false
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(NavBar)
Vue.use(Cascader)
Vue.use(Popup)
Vue.use(ImagePreview)
Vue.use(Overlay)
Vue.use(Picker)
Vue.use(Uploader)
Vue.use(Sticky)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(List)
Vue.use(PullRefresh)
Vue.use(Loading)
Vue.use(Toast)
Vue.use(Empty)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Stepper)
Vue.use(Divider)
Vue.use(Swipe)
Vue.use(SwipeItem)
// router.beforeEach(function (to, from, next) {
//   if (to.meta.needLogin) {
//     if (localStorage.getItem('mooerH5AllUser')) {
//       // console.log('已登录')
//       next();
//     }else {
//       // console.log('未登录')
//       next({
//         path:'/pwdLogin'
//       });
//     }
//   }else{
//     next();
//   }
// })
// import 'vant/lib/index.css'
new Vue({
  router,
  i18n,
  el: '#app',
  render: h => h(App)
}).$mount('#app')

Vue.prototype.toBack = function () {
  this.$router.go(-1)
}
Vue.prototype.fnGoodsDetails = function (idd) {
  this.$router.push({
    name: 'goodsDetail',
    params: { id: idd }
  })
}
