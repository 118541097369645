<template>
    <div id="policy" class="flex-wrap flex-vertical bg1">
        <div class="header before">
            <img :src="require('@/assets/leftArrow.png')" class="leftArrow">
            <div class="hearderTitle">Privacy Policy</div>
        </div>
        <div class="fwb">fwb</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            phone: ''
        }
    },
    methods: {
        initData() {

        },
    },
    mounted() {
        this.initData()
    },

}
</script>


<style scoped>
.bg1 {
	width: 100%;
	height: 100vh;
	background: url('@/assets/bg1.png') no-repeat left top;
	background-size: 95% auto;
}

#policy {
    height: 100vh;
}
.fwb{
    padding: 16px;
}
</style>