<template>
  <div style="padding-bottom:68px;">
    <div class="header before">
      <img :src="require('@/assets/leftArrow.png')" class="leftArrow"  @click="toBack()">
      <div class="hearderTitle">{{ $t('headGRZXObj.dzgl') }}</div>
    </div>

    <van-empty style="margin: auto" :description="$t('new.no1')" v-if="list.length == 0" />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" finished-text="" @load="onLoad">

        <div class="box">

          <div class="card m_t12" :class="{ card_active: item.addr_default == 1 }" @click="fnSelect(item)" v-for="(item, index) in list" :key="index">
            <div class="name flex-wrap">
              <div class="flex-con">{{ item.addr_xing + item.addr_name }}{{ item.addr_default ==
                            1 ? ('-' + $t('addressObj.mrdz')) : '' }}</div>
              <img :src="require('@/assets/arrow3.png')" class="arrow"  @click.stop="fnDetail(item.addr_id)">
            </div>
            <div class="info">{{ item.addr_address || '' }}</div>
            <div class="info">{{ item.addr_postal || '' }}（{{ $t('addressObj.yb') }}）</div>
            <div class="phone flex-wrap">
              <div class="flex-con">{{ item.addr_phone || '' }}</div>
              <img :src="require('@/assets/del.png')" class="arrow" @click.stop="fnDelAddress(item.addr_id)">
            </div>
          </div>

        </div>

      </van-list>
    </van-pull-refresh>

    <div class="bottom flex-wrap">
      <div class="btn btn2 flex-con" @click="fnDetail()">{{ $t('addressObj.dzgl') }}</div>
    </div>
  </div>
</template>

<script>
import { addressList, delAddress } from '../api'
export default {
  data() {
    return {
      page: 1,
      count: 0,
      list: [],
      loading: true,
      finished: false,
      refreshing: false,
    }
  },
  methods: {
    fnDelAddress(idd) {
      let params = {
        addr_id: idd
      }
      delAddress(params).then((data) => {
        if (data.status == 200) {
          this.$toast(data.msg)
          this.page1=1
          this.list=[];
          this.initData()
        }
      })
    },
    initData() {
      addressList({
        page: this.page
      }).then((data) => {
        if (data.status == 200) {
          this.count = data.count;
          if (this.refreshing) {
            this.list = data.msg
            this.refreshing = false
            if (this.list.length >= this.count) {
              this.finished = true
            }
            return
          }
          this.list = this.list.concat(data.msg)
          this.loading = false
          if (this.list.length >= this.count) {
            this.finished = true
          }
        }
      })
    },
    onLoad() {
      this.page++
      this.initData()
    },
    onRefresh() {
      this.finished = false
      this.loading = true
      this.page = 1
      this.initData()
    },
    fnDetail(id) {
        this.$router.push({ name: 'addAddress', params: { addr_id: id } }) 
    },
    fnSelect(item){
      if(this.$route.params.type){
        localStorage.setItem('wlAddress', JSON.stringify(item));
        this.$router.go(-1)
      }  
    }
  },
  mounted() {
    this.initData()
  },

}
</script>


<style scoped>
.van-list {
  min-height: calc(100vh - 50px);
}
.box {
  padding: 0 16px 12px;
}
.card {
  border-radius: 4px;
  border: 1px solid #008dd1;
  padding: 16px 10px 10px;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  line-height: 20px;
}
.arrow {
  width: 24px;
  height: 24px;
}
.name {
  font-weight: 500;
  font-size: 14px;
  color: #018ecf;
  margin-bottom: 8px;
}
.info {
  margin-bottom: 2px;
}
.phone {
  align-items: center;
}
.card_active {
  background: #eaeff6;
  border: 1px solid #eaeff6;
}
.bottom {
  padding: 12px 16px;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
}
.btn2 {
  padding: 0 10px;
  height: 44px;
  background: #018ecf;
  border-radius: 31px;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  line-height: 44px;
  text-align: center;
}
</style>