<template>
  <div >

    <div class="headerTop flex-wrap">
      <img :src="require('@/assets/logo.png')" class="logo">
      <div class="flex-con"></div>
      
      <!-- <img :src="require('@/assets/side.png')" @click="show = true" class="side" style="margin-right:12px;">
      <img :src="require('@/assets/car.png')" class="side" @click="fnCar"> -->
      <van-dropdown-menu  active-color="#018ECF">
        <van-dropdown-item v-model="value" :options="option1" @change="changLang" />
      </van-dropdown-menu>
    </div>
    <!-- <div class="searchAll">
      <div class="searchBox flex-wrap">
        <input type="text" :placeholder="$t('headSSCP')" class="flex-con" v-model="keyword">
        <img :src="require('@/assets/search.png')" class="search" @click="fnSearch">
      </div>
    </div> -->

    <div class="top" style="box-sizing: border-box; padding: 0 16px;margin-top: 10px;">
      <!-- <div class="top1 flex-wrap">
        <img :src="item.banner_image" class="banner" v-for="(item, index) in banner" :key="'banner_' + index">
      </div> -->
      <van-swipe class="my-swipe flex-wrap" style="width:100%;" :autoplay="2000" indicator-color="#008DD1">
      <van-swipe-item v-for="(item, index) in banner" :key="'banner_' + index">
        <img lazy-load :src="item.banner_image" style=" width: 100%;height:164px;object-fit: cover;" />
      </van-swipe-item>
    </van-swipe>
    <div style="display: flex;justify-content: space-between;margin-top: 5px;">
      <div style="text-align: center;"@click="fnClass(0)">
        <img src="../assets/allc.png" alt="" style="width: 52px;height: 52px;">
        <p style="text-align: center;font-size: 13px;color: #333333;margin-top: 5px;">{{ $t('mainCPFL') }}</p>
      </div>
      <div style="text-align: center;"@click="fnAbout(7)">
        <img src="../assets/abos.png" alt="" style="width: 52px;height: 52px;">
        <p style="text-align: center;font-size: 13px;color: #333333;margin-top: 5px;">{{ $t('headGYWM') }}</p>
      </div>
      <div style="text-align: center;"@click="fnAbout(4)">
        <img src="../assets/cont.png" alt="" style="width: 52px;height: 52px;">
        <p style="text-align: center;font-size: 13px;color: #333333;margin-top: 5px;">{{ $t('headLXWM') }}</p>
      </div>
      <div style="text-align: center;"@click="fnSheet">
        <img src="../assets/sheet.png" alt="" style="width: 52px;height: 52px;">
        <p style="text-align: center;font-size: 13px;color: #333333;margin-top: 5px;">{{ $t('xjd.txxjd') }}</p>
      </div>
    </div>
    <div class="searchAll">
      <div class="searchBox flex-wrap">
       
        <img :src="require('@/assets/search.png')" class="search" @click="fnSearch" style="margin-left: 9px;">
        <input type="text" :placeholder="$t('headSSCP')" class="flex-con" style="background-color: #F8F8F8;border-right: 1px solid #008DD1;" v-model="keyword">
        <div style="font-size: 12px;color: #008DD1;padding: 10px;" @click="fnSearch">{{ $t('xjd.ss') }}</div>
      </div>
    </div>
    </div>
    <!-- <div class="title">{{ $t('mainCPFL') }}</div>
    <div class="top" style="height:30px;margin-top:7px;">
      <div class="top1 flex-wrap">
        <div class="label"  v-for="(item, index) in allFirstGoodsCate"  @click="fnClass(item.cate_id)" :key="'allFirstGoodsCate_' + index">{{
        $i18n.locale == 'en' ? item.cate_name_en
          : $i18n.locale == 'es' ? item.cate_name_es : item.cate_name_tp }}</div>
      </div>
    </div> -->
    <div class="title">{{ $t('mainCPFL') }}</div>
    <div style="padding: 10px 10px 60px 10px;">
      <div class="goods" style="width: calc((100% - 10px) / 2);margin-top: 10px;display: inline-block;overflow: hidden;"   @click="fnToCate(item.cate_id, $i18n.locale == 'en' ? item.cate_name_en
        : $i18n.locale == 'es' ? item.cate_name_es : item.cate_name_tp)" v-for="(item, index) in allFirstGoodsCate"
      :key="'allFirstGoodsCate_' + item.cate_id + '_' + index">
      <div >
        <img :src="item.cate_image" class="goodsImg">
        <div class="goods_right flex-con" style="width: 166px;text-align: left;margin-top: 10px;font-size: 16px;color: #333333;">
          <div class="goodsTitle">{{ $i18n.locale == 'en' ? item.cate_name_en
        : $i18n.locale == 'es' ? item.cate_name_es : item.cate_name_tp }}</div>
          <!-- <div>
            $ {{ item.goods_cu_price || '' }} <span class="discount">$ {{ item.goods_price || '' }}</span>
          </div> -->
        </div>
      </div>
      <!-- <div class="flex-wrap">
        <div style="width:93px;"></div>
        <div class="flex-con" style="height:1px;border-bottom:1px solid #E3E3E3;"></div>
      </div> -->
    </div>
    </div>
    
    <van-popup v-model="show" position="left" :style="{ width: '55%', height: '100%' }">
      <div>
        <div class="blue">
          <div class="flex-wrap">
            <div class="flex-con"></div>
            <img :src="require('@/assets/person.png')" class="person"  @click="fnMy">
          </div>
          <div class="name">{{ user_name }} {{ user_xing }}</div>
          <div>{{ email }}</div>
        </div>
        <div class="box">
          <div class="fl" @click="fnClass(0)">{{ $t('mainCPFL') }}</div>
        </div>
        <div class="kong"></div>
        <div class="box">
          <div class="fl">{{ $t('orderObj.ddxx') }}</div>
          <div class="fl1" @click="fnOrder(0)">{{ $t('headGRZXObj.qbdd') }}</div>
          <div class="fl1" @click="fnOrder(1)">{{ $t('headGRZXObj.dqr') }}</div>
          <div class="fl1" @click="fnOrder(2)">{{ $t('headGRZXObj.dfk') }}</div>
          <div class="fl1" @click="fnOrder(3)">{{ $t('headGRZXObj.dfh') }}</div>
          <div class="fl1" @click="fnOrder(4)">{{ $t('headGRZXObj.dsh') }}</div>
          <div class="fl1" @click="fnOrder(5)">{{ $t('headGRZXObj.ywc') }}</div>
          <div class="fl1"  @click="fnOrder(6)">{{ $t('headGRZXObj.yqx') }}</div>
        </div>
        <div class="kong"></div>
        <div class="box">
          <div class="fl">{{ $t('headGRZXObj.wd') }}</div>
          <div class="fl1" @click="fnMy">{{ $t('jbXX.zhsz') }}</div>
          <div class="fl1" @click="fnAddress">{{ $t('headGRZXObj.dzgl') }}</div>
          <div class="fl1" @click="fnCollect">{{ $t('headGRZXObj.wdsc') }}</div>
          <div class="fl1" @click="fnAbout(2)">{{ $t('headYSZC') }}</div>
          <div class="fl1" @click="fnAbout(7)">{{ $t('headGYWM') }}</div>
          <div class="fl1" @click="fnAbout(4)">{{ $t('headLXWM') }}</div>
        </div>
        <div class="kong"></div>
        <div class="box">
          <div class="fl">{{ $t('xjd.xjd') }}</div>
          <div class="fl1" @click="fnList">{{ $t('xjd.xjdlb') }}</div>
          <div class="fl1" @click="fnSheet">{{ $t('xjd.txxjd') }}</div>
          <div class="bottom flex-wrap">
            <img :src="require('@/assets/out.png')" class="out">
            <div @click="exit">{{ $t('headGRZXObj.tczh') }}</div>
          </div>
        </div>
      </div>
    </van-popup>
    <van-tabbar   route>
  <van-tabbar-item replace to="/" icon="wap-home-o">{{ $t('xjd.sy') }}</van-tabbar-item>
  <van-tabbar-item replace to="/sortThree/0"  icon="apps-o">{{ $t('xjd.fl') }}</van-tabbar-item>
  <van-tabbar-item replace to="/shoppingCar" icon="shopping-cart-o">{{ $t('xjd.gwc') }}</van-tabbar-item>
  <van-tabbar-item replace to="/mine"  icon="contact-o">{{ $t('xjd.wd') }}</van-tabbar-item>
</van-tabbar>
  </div>
</template>

<script>
import { bannerList, firstGoodsCate, recommendGoods,userBaseInfo } from '../api';

export default {
  components: {},
  data() {
    return {
      show: false,
      keyword: '',
      banner: [],
      allFirstGoodsCate: [],
      recommendGoodsData: [],
      user_name: '',
      user_xing: '',
      email: '',
      value: 0,
      option1: [
        { text: 'English', value: 'English' },
        { text: 'Español', value: 'Español' },
        { text: 'Português', value: 'Português' },
      ],
      
    }
  },
  methods: {
    changLang(e){
      this.$i18n.locale = e;
      localStorage.setItem('lang', e)
    },
    initFirstGoodsCate() {
      let params = {
        type: 1
      }
      firstGoodsCate(params).then((data) => {
        if (data.status == 200) {
          this.allFirstGoodsCate = data.msg
        }
      })
    },
    initLB() {
      let params = {}
      bannerList(params).then((data) => {
        if (data.status == 200) {
          this.banner = data.msg
        }
      })
    },
    initRecommendGoods() {
      let params = {}
      recommendGoods(params).then((data) => {
        if (data.status == 200) {
          this.recommendGoodsData = data.msg
        }
      })
    },
    fnToCate(cate_id1, cate_id1_name) {
      this.$router.push({
        name: 'sortThree',
        params: {
          id1: cate_id1,
          id1_name: cate_id1_name
        }
      })
    },
    initData() {
      userBaseInfo({
        
      }).then((data) => {
        if (data.status == 200) {

          this.user_name = data.msg.user_name;
          this.user_xing ='';
          this.email = data.msg.user_email;
          
        }
      })
    },
    fnMy(){
      this.$router.push({ name: 'accountSetting', params: {} })
    },
    fnClass(id) {
      this.$router.push({ name: 'sortThree', params: {id:id} })
    },
    fnCar() {
      this.$router.push({ name: 'shoppingCar', params: {} })
    },
    fnOrder(status) {
      this.$router.push({ name: 'order', params: {status:status} })
    },
    
    fnAddress() {
      this.$router.push({ name: 'addressList', params: {} })
    },
    fnCollect() {
      this.$router.push({ name: 'collect', params: {} })
    },
    fnAbout(type) {
      this.$router.push({ name: 'aboutUs', params: {type:type} })
    },
    fnList() {
      this.$router.push({ name: 'inquiryList', params: {} })
    },
    fnSheet() {
      this.$router.push({ name: 'inquirySheet', params: {} })
    },
    fnSearch() {
      this.$router.push({
        name: 'search', params: {
          keyword: this.keyword
        }
      })
    },
    exit() {
      localStorage.setItem('allUserMrh5', '')
      this.$router.push({ name: 'login' })
    },
  },
  mounted() {
    this.value=localStorage.getItem('lang') || 'English'
    this.initLB();
    this.initFirstGoodsCate();
    this.initRecommendGoods();
    this.initData();
  },
}
</script>
<style scoped>
.headerTop {
  height: 44px;
  align-items: center;
  padding: 0 16px;
}

.logo {
  width: 128px;
  height: 30px;
}

.side {
  width: 24px;
  height: 24px;
}

.searchAll {
  padding:  10px 0 10px 0;
}

.searchBox {
  height: 36px;
  background: #F8F8F8;
  /* box-shadow: 0px 0px 10px 0px rgba(1, 142, 207, 0.1); */
  border-radius: 18px;
  align-items: center;
  padding-right: 9px;
  border: 1px solid #008DD1;
}

input[type="text"] {
  border: none;
  outline: none;
  padding: 0 9px;
  color: 333;
  font-size: 14px;
}

input[type="text"]::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #999;
}

.search {
  width: 17px;
  height: 17px;
}

.top {
  width: 100%;
  height: 297px;
  overflow: hidden;
  overflow-x: scroll;
}

.top::-webkit-scrollbar {
  height: 0;
}

.top1 {
  width: fit-content;
  padding-left: 16px;
}

.banner {
  display: block;
  width: 285px;
  height: 239px;
  margin-right: 16px;
  border-radius: 12px;
}

.title {
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  padding-left: 16px;
  padding-top: 20px;
  padding-bottom: 7px;
}

.label {
  height: 28px;
  border-radius: 17px;
  border: 1px solid #e3e3e3;
  padding: 0 22px;
  line-height: 28px;
  margin-right: 12px;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}



.goodsImg {
  width: 100%;
  height: 166px;
  
}

.goods_right {
  /* border-bottom: 1px solid #E3E3E3; */
  color: #666;
  font-size: 14px;
}

.goodsTitle {
  font-weight: 500;
  line-height: 16px;
  color: #333;
  margin-bottom: 6px;
  height: 32px;
}

.discount {
  font-weight: 400;
  font-size: 12px;
  color: #999;
  text-decoration: line-through;
}

.blue {
  background: #018ECF;
  padding: 12px 12px 22px;
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
  line-height: 17px;
}

.person {
  width: 24px;
  height: 24px;
}

.name {
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 22px;
  margin-bottom: 4px;
}

.box {
  padding: 0 12px;
}

.fl {
  height: 44px;
  line-height: 44px;
  font-weight: 500;
  font-size: 14px;
  color: #333333;
}

.kong {
  height: 6px;
  background: #F8F8F8;
}

.fl1 {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  margin-bottom: 12px;
}

.out {
  width: 24px;
  height: 24px;
  margin: 30px 10px 30px 0;
}

.bottom {
  font-weight: 500;
  font-size: 14px;
  color: #333333;
  align-items: center;
}
::v-deep .van-dropdown-menu__bar{
    box-shadow:none;
}
::v-deep .van-dropdown-menu__title{
    font-size: 16px;
    color: #333;
}
::v-deep .van-dropdown-menu__item{
    -webkit-flex: inherit;
    padding: 0 16px;
}
.goods:nth-child(2n + 1) {
    margin-right: 10px;
}
</style>