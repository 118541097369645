<template>
  <div>
    <div class="header before">
      <img :src="require('@/assets/leftArrow.png')" class="leftArrow"  @click="onClickLeft">
      <div class="hearderTitle">{{title}}</div>
    </div>
    <div class="box">
      <div class="top flex-wrap">
        <div class="flex-con"></div>
        <div class="top1" @click="show=true">{{ $t('new.sx') }}</div>
        <img :src="require('@/assets/arrow2.png')" @click="show=true" class="topArrow">
      </div>
      <van-empty style="margin: auto" :description="$t('new.no1')" v-if="list.length == 0" />
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="" @load="onLoad">

          <div class="goodsAll" @click="fnDetail(item.order_id)"  v-for="(item, index) in list" :key="index">
            <div class="name flex-wrap">
              <div class="flex-con">#{{item.order_number}}</div>
              <div class="blue"> 
                {{ item.order_status == 1 ? $t('headGRZXObj.dqr') :
                  item.order_status == 2 ?$t('headGRZXObj.dfk') : 
                  item.order_status == 3 ? $t('headGRZXObj.dfh') :
                  item.order_status == 4 ?$t('headGRZXObj.dsh') :
                  item.order_status == 5 ? $t('headGRZXObj.ywc') :
                  $t('headGRZXObj.yqx') }}
                </div>
            </div>
            <div class="goods flex-wrap" v-for="(item, index) in item.orderGoods" :key="item.order_id">
              <img :src="item.goods_thumb" class="goodsImg">
              <div class="before flex-con">
                <div class="title  flex-wrap">
                  <div class="flex-con aui-ellipsis-2">{{ $i18n.locale == 'en' ?item.goods_name_en: $i18n.locale == 'es' ? item.goods_name_es : item.goods_name_tp }}</div>
                </div>
                <div class="info">{{ item.packaging_name }}</div>
                <div class="info">{{ item.spec_value }}</div>
                <div class="price flex-wrap">
                  <div class="flex-con">$ {{ item.of_price }}</div>
                  <div class="num"> X {{ item.of_quantity }}</div>
                </div>
              </div>
            </div>
            <div class="priceAll flex-wrap">
              <div class="flex-con">{{ item.order_time }}</div>
              <div>{{ $t('goodsObj.zj') }}：<span class="price1">${{ item.order_total_price }}</span></div>
            </div>
            <div class="btnAll flex-wrap">
              <div class="flex-con"></div>
              <div class="btn1"  v-show="item.order_status == 1 || item.order_status == 2" @click.stop="fnSure(2,item.order_id)">{{ $t('orderObj.qxdd') }}</div>
              <div class="btn2"  v-show="item.order_status == 1" @click.stop="fnSure(1,item.order_id)">{{ $t('orderObj.qrdd') }}</div>
              <div class="btn2"  v-show="item.order_status == 4" @click.stop="fnSure(3,item.order_id)">{{ $t('orderObj.qrsh') }}</div>
              <div class="btn2"  v-show="item.order_status == 5 && item.orderGoods.length == 1" @click.stop="fnGoodsDetails(item.orderGoods[0].of_goods_id)">{{ $t('orderObj.zlyd') }}</div>
            </div>
          </div>

        </van-list>
      </van-pull-refresh>

    </div>

    <van-popup v-model="show" position="right" :style="{  width: '50%',height: '100%'  }">
      <div class="sx">
        <div class="one flex-wrap">
          <input type="radio" name="radio" value="0" v-model="status">
          <div>{{ $t('new.qb') }}</div>
        </div>
        <div class="one flex-wrap">
          <input type="radio" name="radio" value="1" v-model="status">
          <div>{{ $t('headGRZXObj.dqr') }}</div>
        </div>
        <div class="one flex-wrap">
          <input type="radio" name="radio" value="2" v-model="status">
          <div>{{ $t('headGRZXObj.dfk') }}</div>
        </div>
        <div class="one flex-wrap">
          <input type="radio" name="radio" value="3" v-model="status">
          <div>{{ $t('headGRZXObj.dfh') }}</div>
        </div>
        <div class="one flex-wrap">
          <input type="radio" name="radio" value="4" v-model="status">
          <div>{{ $t('headGRZXObj.dsh') }}</div>
        </div>
        <div class="one flex-wrap">
          <input type="radio" name="radio" value="5" v-model="status">
          <div>{{ $t('headGRZXObj.ywc') }}</div>
        </div>
        <div class="one flex-wrap">
          <input type="radio" name="radio" value="6" v-model="status">
          <div>{{ $t('headGRZXObj.yqx') }}</div>
        </div>
      </div>
      <div class="bottom">
        <div class="btn" @click="submit">{{ $t('carObj.tj') }}</div>
      </div>
    </van-popup>
    <van-popup v-model="show1" round>
      <div class="tipBox">
        <div style="color:#333;font-size:14px;margin-bottom: 20px">
          {{ts}}
        </div>
        <div class="align-between">
          <div class="tipBtn1 align-center" @click="fnCommit">{{ $t('carObj.qr') }}</div>
          <div class="tipBtn2 align-center"  @click="show1=false">{{ $t('carObj.qux') }}</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
      
<script>
import {orderList, sureOrder, overOrder, cancelOrder } from '../api'
export default {
  data() {
    return {
      show: false,
      show1:false,
      status: '0',
      page: 1,
      count: 0,
      list: [],
      loading: true,
      finished: false,
      refreshing: false,
      ts:'',
      orderId:'',
      where:0,
      title:''
    }
  },
  methods: {
    fnSure(type,id){
        this.show1=true;
        this.where=type;
        if(this.where==1){
            this.ts=this.$t('new.tip1');
        }else if(this.where==2){
            this.ts=this.$t('new.tip2');
        }else{
            this.ts=this.$t('new.tip3');
        }
        this.orderId=id;
    },
    fnCommit(){
        if(this.where==1){
            this.fnSureOrder(this.orderId);
        }else if(this.where==2){
            this.fnCancleOrder(this.orderId);
        }else{
            this.fnSureSh(this.orderId) 
        }
    },
    fnSureOrder(idd) {
      let params = {
        order_id: idd
      }
      sureOrder(params).then((data) => {
        if (data.status == 200) {
          this.$toast(data.msg)
          this.show1=false;
          this.page = 1
          this.list=[]
          this.initData()
        }
      })
    },
    fnCancleOrder(idd) {
      let params = {
        order_id: idd
      }
      cancelOrder(params).then((data) => {
        if (data.status == 200) {
          this.$toast(data.msg)
          this.show1=false;
          this.page = 1
          this.list=[]
          this.initData()
        }
      })
    },
    fnSureSh(idd) {
      let params = {
        order_id: idd
      }
      overOrder(params).then((data) => {
        if (data.status == 200) {
          this.$toast(data.msg)
          this.show1=false;
          this.page = 1
          this.list=[]
          this.initData()
        }
      })
    },
    initData() {
      orderList({
        status: this.status,
        page: this.page
      }).then((data) => {
        if (data.status == 200) {
          this.count = data.count;
          if (this.refreshing) {
            this.list = data.msg
            this.refreshing = false
            if (this.list.length >= this.count) {
              this.finished = true
            }
            return
          }
          this.list = this.list.concat(data.msg)
          this.loading = false
          if (this.list.length >= this.count) {
            this.finished = true
          }
        }
      })
    },
    onLoad() {
      this.page++
      this.initData()
    },
    onRefresh() {
      this.finished = false
      this.loading = true
      this.page = 1
      this.initData()
    },
    changeNums(e) {
      
    },
    submit() {
      this.page = 1
      this.list=[];
      this.initData()
      this.show=false;
    },
    fnDetail(id) {
      this.$router.push({ name: 'orderDetail', params: {order_id:id} })
    },
    onClickLeft() {
      if(this.fromName=='submit'){
        this.$router.go(-2)
      }else{
        this.$router.go(-1)
      }
      
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      console.log(from.name);
        vm.fromName = from.name
    })
  },
  mounted() {
    this.status = this.$route.params.status;
    if(this.status==0){
      this.title=this.$t('headGRZXObj.qbdd')
    }else if(this.status==1){
      this.title=this.$t('headGRZXObj.dqr')
    }else if(this.status==2){
      this.title=this.$t('headGRZXObj.dfk')
    }else if(this.status==3){
      this.title=this.$t('headGRZXObj.dfh')
    }else if(this.status==4){
      this.title=this.$t('headGRZXObj.dsh')
    }else if(this.status==5){
      this.title=this.$t('headGRZXObj.ywc')
    }else if(this.status==6){
      this.title=this.$t('headGRZXObj.yqx')
    }
    
    console.log(this.status);
    this.initData()
  },

}
  </script>
  <style scoped>
.van-list {
  min-height: calc(100vh - 50px);
}
.box {
  padding: 0 16px;
}
.goods {
  padding: 10px 10px 0;
  margin-top: 10px;
}
.goodsImg {
  width: 112px;
  height: 112px;
  margin-right: 8px;
}
.title {
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  line-height: 16px;
  align-items: center;
  margin-top: 5px;
}

.info {
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  margin-top: 6px;
}
.price {
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  align-items: center;
  margin-top: 16px;
}
.priceAll {
  font-weight: 400;
  font-size: 12px;
  color: #999;
  height: 42px;
  padding: 0 16px;
  align-items: center;
}
.priceLeft {
  color: #666666;
}
.goodsAll {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding-bottom: 12px;
  margin-bottom: 12px;
}
.name {
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  border-bottom: 1px solid #e2e2e2;
  align-items: center;
  height: 44px;
  padding: 0 10px;
}
.blue {
  color: #018ecf;
  line-height: 20px;
}
.price1 {
  color: #333;
  font-weight: 500;
}
.btnAll {
  align-items: center;
}
.btn1 {
  padding: 0 10px;
  height: 32px;
  border-radius: 31px;
  border: 1px solid #018ecf;
  font-weight: 400;
  font-size: 14px;
  color: #018ecf;
  line-height: 32px;
  text-align: center;
}
.btn2 {
  padding: 0 10px;
  height: 32px;
  background: #018ecf;
  border-radius: 31px;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  line-height: 32px;
  text-align: center;
  margin-left: 8px;
  margin-right: 10px;
}
.top {
  height: 42px;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}
.topArrow {
  width: 18px;
  height: 18px;
  margin-left: 12px;
}
.sx {
  padding: 15px;
}
.one {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  align-items: center;
  margin-bottom: 12px;
}
input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #979797;
  border-radius: 1px;
  margin-right: 16px;
}

input[type="radio"]:checked {
  border: 2px solid #018ecf;
  background: url("@/assets/checked.png") no-repeat center center;
  background-size: 18px 18px;
}
.bottom {
  position: fixed;
  width: 100%;
  bottom: 20px;
  padding: 0 16px;
}
.btn {
  height: 54px;
  background: #018ecf;
  border-radius: 31px;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  line-height: 54px;
  text-align: center;
}
.tipBox {
  width: 275px;
  height: 105px;
  background: #ffffff;
  border-radius: 4px;
  padding: 20px;
  background: #fff;
  text-align: center;
  box-sizing: border-box;
}

.tipBtn1 {
  width: 110px;
  height: 25px;
  border-radius: 4px;
  border: 1px solid #018ecf;
  color:#018ecf;
}

.tipBtn2 {
  width: 110px;
  height: 25px;
  background:#018ecf;
  color:#fff;
  border-radius: 4px;
}
</style>