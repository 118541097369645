<template>
  <div>
    <div class="header before">
      <img :src="require('@/assets/leftArrow.png')" class="leftArrow">
      <div class="hearderTitle">classification</div>
    </div>
    <div>
      <div class="left">
        <div class="sort sort_active">Pigmentos</div>
        <div class="sort">Materias primas</div>
      </div>
      <div class="sortRight">

        <div class="top" style="height:30px;margin-top:7px;">
          <div class="top1 flex-wrap">
            <div class="label label_active">Pigmentos</div>
            <div class="label">Pulpa de color</div>
            <div class="label">Pigmentos</div>
            <div class="label">Pulpa de color</div>
          </div>
        </div>
        <div class="middle flex-wrap"  @click="fnSort">
          <div class="flex-con">Pigmentos 2</div>
          <img :src="require('@/assets/arrow.png')" class="arrow">
        </div>
        <div class="box">
          <div class="goods">
            <img :src="require('@/assets/static/p4.png')" class="goodsImg">
            <div class="title aui-ellipsis-2">Purity 99.9% Cerium Oxide CeH2O3 Lig …</div>
            <div class="price">$ 20.00</div>
          </div>
          <div class="goods">
            <img :src="require('@/assets/static/p4.png')" class="goodsImg">
            <div class="title aui-ellipsis-2">Purity 99.9% Cerium Oxide CeH2O3 Lig …</div>
            <div class="price">$ 20.00</div>
          </div>
          <div class="goods">
            <img :src="require('@/assets/static/p4.png')" class="goodsImg">
            <div class="title aui-ellipsis-2">Purity 99.9% Cerium Oxide CeH2O3 Lig …</div>
            <div class="price">$ 20.00</div>
          </div>
        </div>

      </div>

    </div>

  </div>
</template>
    
    <script>
export default {
  data() {
    return {

    }
  },
  methods: {
    initData() {

    },
    fnSort() {
      this.$router.push({name: 'sortTwo',params:{}})
    },
  },
  mounted() {
    this.initData()
  },

}
    </script>
    
    
  <style scoped>
.box {
  padding: 0 16px;
}
.goods {
  display: inline-block;
  width: calc((100% - 10px) / 2);
  background: #f8f8f8;
  border-radius: 4px;
  padding: 14px 10px;
  margin-bottom: 10px;
}
.goods:nth-child(2n + 1) {
  margin-right: 10px;
}
.goodsImg {
  width: 100%;
  height: 138px;
  margin-bottom: 10px;
}
.title {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 16px;
  margin-bottom: 6px;
}
.price {
  font-weight: 500;
  font-size: 16px;
  color: #333333;
}
.top {
  width: 100%;
  height: 44px;
  overflow: hidden;
  overflow-x: scroll;
}
.top::-webkit-scrollbar {
  height: 0;
}
.top1 {
  width: fit-content;
  padding-left: 16px;
}
.label {
  width: max-content;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  margin-right: 16px;
}
.label_active {
  font-weight: 500;
  font-size: 16px;
  color: #333;
}
.middle {
  padding: 0 16px;
  font-weight: 500;
  font-size: 14px;
  color: #333333;
  height: 40px;
  align-items: center;
}
.arrow {
  width: 20px;
  height: 20px;
}
.left {
  float: left;
  width: 35%;
  height: 100vh;
  background: #f8f8f8;
  padding: 16px 12px 0;
}
.sortRight {
  width:65%;
  overflow: hidden;
  overflow-y: auto;
  background-color: #fff;
  height: 100vh;
  -webkit-overflow-scrolling: touch;
}
.sort{
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    margin-bottom: 16px;
}
.sort_active{
    font-weight: 500;
    font-size: 16px;
    color: #018ECF;
}
</style>