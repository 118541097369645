const English = {
    hello: '你好世界',
    headSY: '首页',
    headQYCP: '企业产品',
    headGYWM: '关于我们',
    headLXWM: '联系我们',
    headYSZC: '隐私政策',
    headSSCP: '搜索产品',
    headGRZX: '个人中心',
    headYY: '英语',
    headXBYY: '西班牙语',
    headPTYY: '葡萄牙语',
    mainCPFL: '产品分类',
    mainTJCP: '推荐产品',
    mainTJCPYH: '为您推荐最新优惠产品',
    
    mainCK: '查看',
    footAll: {
        rmlb: '热门类别',
        fwzc: '服务政策',
        sjbhzc: '数据保护政策',
        yszc: '隐私政策',
        gywm: '关于我们',
        gsjs: '公司介绍',
        lxwm: '联系我们',
        dz: '地址',
        zb: '总部',
        fwtk: '服务条款'
    },
    headGRZXObj: {
        wd: '我的',
        jbxx: '基本资料',
        dzgl: '地址管理',
        wdsc: '我的购买清单',
        qbdd: '全部订单',
        dqr: '待确认',
        dfk: '待付款',
        dfh: '待发货',
        dsh: '待收货  ',
        ywc: '已收货',
        yqx: '已取消',
        tczh: '退出登录',
        zhzx: '账号中心',
        ddzx: '订单中心',
        gwc: '购物车'
    },
    jbXX: {
        xing: '姓',
        ming: '名',
        dzyx: '电子邮箱',
        gs: '公司',
        zw: '职位',
        dh: '电话',
        dhhm: '电话号码',
        tj: '添加',
        mm: '密码',
        ggmm: '更改密码',
        ndqmm: '您当前密码',
        ndxmm: '您的新密码',
        cxsrndxmm: '重新输入您的新密码',
        qsr: '请输入',
        lcbyz: '两次新密码输入不一致',
        zhsz: '帐户设置',
        jbxx: '基本信息',
        xm: '姓名',
        genggai: '更改',
        ggyz: '更改 / 验证',
        yxdz: '邮箱地址',
        yxqr: '邮箱确认',
        qux: '取消',
        qr: '确认',
        bjxm: '编辑姓名',
        dqmm: '当前密码',
        xmm: '新密码',
        qrxmm: '确认新密码',
    },
    addressObj: {
        mo: '设置为默认地址',
        xdz: '新地址',
        xxdz: '详细地址',
        yb: '邮编',
        dzgl: '地址管理',
        mrdz: '默认地址',
        qr: '确认',
        bjdz: '编辑地址'
    },
    cateObj: {
        lb: '类别',
        sx: '属性'
    },
    goodsObj: {
        bz: '包装',
        sl: '数量',
        xzgg: '选择规格',
        ljgm: '立即购买',
        zj: '总计',
        jrgwc: '加入购物车',
        cpxq: '产品详情',
        tjsc: '添加收藏',
        qxsc: '取消收藏',
    },
    carObj: {
        sc: '删除',
        qx: '全选',
        cpxx: '产品信息',
        cpgg: '产品规格',
        dj: '单价',
        sl: '数量',
        xj: '小计',
        gwczj: '购物车总计',
        je: '金额',
        tj: '提交',
        xz: '请选择删除的产品',
        qrsc: '确认删除吗',
        qux: '取消',
        qr: '确认',
        xzcp: '请选择产品',
        tjdd: '提交订单',
        cpqd: '产品清单',
        tjdz: '添加地址',
        qxzdz: '请选择地址'
    },
    orderObj: {
        ddxx: '订单信息',
        gg: '规格',
        zt: '状态',
        cz: '操作',
        ddsj: '订单时间',
        ddbh: '订单编号',
        ckdd: '查看订单',
        qrdd: '确认订单',
        qxdd: '取消订单',
        qrsh: '确认收货',
        zlyd: '再来一单',
        ddxq: '订单详情',
        qm: '全名',
        dh: '电话',
        wl: '物流',
        w: '无',
        hwys: '货物运输'
    },
    logon: {
        dl: '登录',
        dl1: '探索摩尔化工',
        dl2: '快速下单',
        dl3: '直接对接 拥有最优惠价格',
        dl4: '高效恢复',
        dl5: '请输入电子邮箱',
        dl6: '请输入密码',
        dl7: '忘记密码',
        dl8: '注册',
        dl9: '请输入验证码',
        dl10: '再次输入密码',
        dl11: '发送验证码',
        dl12: '验证码错误',
        dl13: '两次密码不一致',
        dl14: '发送成功',
        dl15: '重新获取',
        dl16: '完善信息',
        dl17: '请输入名字',
        dl18: '请输入姓氏',
        dl19: '请输入邮箱',
        dl20: '请输入电话',
        dl21: '请输入公司',
        dl22: '请输入职位',
        dl23: '审核中',
        dl24: '重新提交',
        dl25: '提交',
        dl26: '提示',
        dl27: '审核失败',
        dl28: '审核中，请耐心等待',
        yx: '邮箱',
        mm: '密码',
        cz: '重置',
        myzh: '没有账号',
        agree: '继续，即表示您同意我们的',
        jx: '继续',
        xgdlmm: '修改登录密码'
    },
    xjd: {
        xjd: '询价单',
        txxjd: '填写询价单',
        xjdlb: '询价单列表',
        xjcp: '询价产品',
        pthf: '平台回复',
        lxr: '联系人',
        lxrxx: '联系人电话',
        srlxr: '输入联系人',
        srlxrxx: '输入联系人电话',
        cpsl: '填写需要查询的产品及数量',
        myg:'贸易国',
        cyg:'出运港',
        mdg:'目的港',
        ysfs:'支付方式',
        mysysj:'贸易术语数据',
        cgood:'选择产品',
        xzgg:'选择规格',
        xzbz:'选择包装',
        txsl:'填写数量',
        xzhb:'选择货币',
        scdd:'生成订单',
        tishi:'提示',
        qx:'取消',
        cg:'确定',
        tsxx:'此操作将永久删除该记录, 是否继续?',
        sccg:'删除成功',
        qxsc:'已取消删除',
        ss:'搜索',
        cygn:'常用功能',
        sy:'首页',
        fl:'分类',
        gwc:'购物车',
        wd:'我的',
        xjdbh:'询价单编号',
        cggsmc:'采购公司名称',
        cggsdz:'采购公司地址',
        cgyxm:'采购员姓名',
        cgydh:'采购员电话',
        yjdz:'邮件地址',
        dj:'单价',
        zongjia:'总价',
        dw:'单位',
        txdw:'填写单位',
        ddss:'到港时间',
        

        
        
    },
    new:{
        qb:'全部',
        tip1:'是否确认订单',
        tip2:'是否取消订单',
        tip3:'是否确认收货',
        sx:'筛选',
        no1:'空空如也',
        no2:'没有更多了',
        tip4:'请选择商品'
    }
}
export default English