<template>
  <div id="inquiryXq" class="flex-wrap flex-vertical bg1" style="padding:0 16px 12px;">
    <div class="header before" style="padding: 0;">
      <img :src="require('@/assets/leftArrow.png')" class="leftArrow" style="left: 0;" @click="toBack()">
      <div class="font-333-18-500 center" style="line-height: 44px;">{{ $t('xjd.xjd') }}</div>
    </div>
    <div style="padding:12px 0;">
      <div class="input_out m_b10" style="display: flex;justify-content: space-between;">
        <div class="font-666-14" style="height: 40px;width: 50%;line-height: 40px;color: #999999;font-size: 16px;">{{
        $t('xjd.xjdbh') }}</div>
        <input type="text" style="text-align: right;height: 40px;font-size: 16px;" v-model="data.order_number" readonly>
      </div>
      <div class="input_out m_b10" style="display: flex;justify-content: space-between;">
        <div class="font-666-14" style="height: 40px;width: 50%;line-height: 40px;color: #999999;font-size: 16px;">{{
        $t('xjd.cggsmc') }}</div>
        <input type="text" style="text-align: right;height: 40px;font-size: 16px;" v-model="data.order_xjd_gs_name" readonly>
      </div>
      <div class="input_out m_b10" style="display: flex;justify-content: space-between;">
        <div class="font-666-14" style="height: 40px;width: 50%;line-height: 40px;color: #999999;font-size: 16px;">{{
       $t('xjd.cggsdz') }}</div>
        <input type="text" style="text-align: right;height: 40px;font-size: 16px;" v-model="data.order_xjd_gs_address" readonly>
      </div>
      <div class="input_out m_b10" style="display: flex;justify-content: space-between;">
        <div class="font-666-14" style="height: 40px;width: 50%;line-height: 40px;color: #999999;font-size: 16px;">{{
        $t('xjd.cgyxm') }}</div>
        <input type="text" style="text-align: right;height: 40px;font-size: 16px;" v-model="data.order_xjd_user_name" readonly>
      </div>
      <div class="input_out m_b10" style="display: flex;justify-content: space-between;">
        <div class="font-666-14" style="height: 40px;width: 50%;line-height: 40px;color: #999999;font-size: 16px;">{{
        $t('xjd.cgydh') }}</div>
        <input type="text" style="text-align: right;height: 40px;font-size: 16px;" v-model="data.order_xjd_user_phone" readonly>
      </div>
      <div class="input_out m_b10" style="display: flex;justify-content: space-between;">
        <div class="font-666-14" style="height: 40px;width: 50%;line-height: 40px;color: #999999;font-size: 16px;">{{
        $t('xjd.yjdz') }}</div>
        <input type="text" style="text-align: right;height: 40px;font-size: 16px;" v-model="data.order_xjd_user_email" readonly>
      </div>
      <div class="input_out m_b10" style="display: flex;justify-content: space-between;">
        <div class="font-666-14" style="height: 40px;width: 50%;line-height: 40px;color: #999999;font-size: 16px;">{{
        $t('xjd.cyg') }}</div>
        <input type="text" style="text-align: right;height: 40px;font-size: 16px;" v-model="data.order_xjd_cyg" readonly>
      </div>
      <div class="input_out m_b10" style="display: flex;justify-content: space-between;">
        <div class="font-666-14" style="height: 40px;width: 50%;line-height: 40px;color: #999999;font-size: 16px;">{{
        $t('xjd.mdg') }}</div>
        <input type="text" style="text-align: right;height: 40px;font-size: 16px;" v-model="data.order_xjd_mdg" readonly>
      </div>
      <div class="input_out m_b10" style="display: flex;justify-content: space-between;">
        <div class="font-666-14" style="height: 40px;width: 50%;line-height: 40px;color: #999999;font-size: 16px;">{{
        $t('xjd.ddss') }}</div>
        <input type="text" style="text-align: right;height: 40px;font-size: 16px;" v-model="data.order_xjd_gettime" readonly>
      </div>
      <div class="input_out m_b10" style="display: flex;justify-content: space-between;">
        <div class="font-666-14" style="height: 40px;width: 50%;line-height: 40px;color: #999999;font-size: 16px;">{{
        $t('xjd.mysysj') }}</div>
        <input type="text" style="text-align: right;height: 40px;font-size: 16px;" v-model="data.order_xjd_mysysj" readonly>
      </div>
      <div class="input_out m_b10" style="display: flex;justify-content: space-between;">
        <div class="font-666-14" style="height: 40px;width: 50%;line-height: 40px;color: #999999;font-size: 16px;">{{
       $t('xjd.ysfs') }}</div>
        <input type="text" style="text-align: right;height: 40px;font-size: 16px;" v-model="data.order_xjd_paytype" readonly>
      </div>
    </div>
    <div class="title">
      {{ $t('xjd.xjcp') }}
    </div>
    <div class="order" v-for="(item, index) in data.orderGoods" :key="index">
      <div class="order_top">{{ $i18n.locale == 'en' ? item.goods_name_en
      : $i18n.locale == 'es' ? item.goods_name_es
        : item.goods_name_tp }}</div>
        <div class="order_top">
          <div >{{ item.spec_value || '' }}</div>
        </div>
      <div class="order_mid">
        <div class="order_mid_left">{{ item.of_quantity || '' }}</div>
        <div class="order_mid_right">{{ item.packaging_name || '' }}</div>
      </div>
      <div class="order_mid">
        <div class="order_mid_left">{{ item.of_danwei || '' }}</div>
        <div class="order_mid_right">{{ item.of_moneytyoe || '' }}</div>
      </div>
      <div class="order_mid" v-if="data.order_total_price>0 ">
        <div class="order_mid_left">{{ item.of_price || '' }}</div>
        <div class="order_mid_right">{{ item.of_total_price || '' }}</div>
      </div>
    </div>
    <div class="title1">
      {{ $t('xjd.pthf') }}
    </div>
    <div class="reply">
      {{ data.order_xjd_huifu || '' }}
    </div>
    <div class="pointer btn align-center" @click="createOrder()" v-if="data.order_xjd_status===2 && data.order_total_price>0 ">{{ $t('xjd.scdd') }}</div>
      <div class="pointer btn2 align-center" v-else>{{ $t('xjd.scdd') }}</div>
  </div>
</template>

<script>
import { alreadyRead, orderDetail, creatOrder } from '../api'
export default {
  data() {
    return {
      data: ''
    }
  },
  methods: {
    initData() {
      orderDetail({
        order_id: this.$route.params.id
      }).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
          console.log(this.data);
        }
      })
    },
    initRead() {
      alreadyRead({
        order_id: this.$route.params.id
      }).then((data) => {
        if (data.status == 200) {
          this.$bus.$emit('xjd')
        }
      })
    },
    createOrder(){
      creatOrder({
        order_id: this.$route.params.id
      }).then((data) => {
        if (data.status == 200) {
          this.$message.success(data.msg)
          location.reload();
        }
      })
    },
  },
  mounted() {
    this.initData()
  },

}
</script>


<style scoped>
.bg1 {
  width: 100%;
 
  background: url("@/assets/bg1.png") no-repeat left top;
  background-size: 95% auto;
}

.input_out {
  border-radius: 10px;
  border: 1px solid #979797;
  box-sizing: border-box;
  padding: 0 12px;
}



.input_out {
  border-radius: 10px;
  border: 1px solid #979797;
  box-sizing: border-box;
 
}

input[type="text"],
input[type="password"],
input[type="tel"] {
  background: rgba(0, 0, 0, 0);
  height: 22px;
  border-radius: none;
  border: none;
  box-sizing: border-box;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
  outline: none;
  display: block;
  width: -webkit-fill-available;
}

input[type="text"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder {
  color: #999;
}

textarea {
  width: 100%;
  height: 159px;
  border: none;
  padding: 16px 0;
  font-size: 14px;
  color: #333;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0);
  outline: none;
  margin: 0;
  font-weight: 400;
}

textarea::-webkit-input-placeholder {
  color: #999;
}

.title {
  color: #333333;
  font-size: 16px;

}
.title1{
  color: #333333;
  font-size: 16px;
  margin-top: 16px;
}

.order {
  width: 100%;
  
  margin-top: 16px;
  box-sizing: border-box;padding: 12px;box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);border-radius: 4px;margin-top: 15px;
  
}
.order_top{
  border-radius: 10px;
  border: 1px solid #979797;
  box-sizing: border-box;
  padding: 0 12px;
  background: rgba(0, 0, 0, 0);
  height: 40px;
  border-radius: none;
  white-space:nowrap;
   overflow:hidden;
   text-overflow:ellipsis; 
  box-sizing: border-box;
  
  font-weight: 400;
  font-size: 16px;
  color: #999999;
  line-height: 40px;
  
  display: block;
  width:100%;
  margin-top: 12px;
}
.order_mid{
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}
.order_mid_left{
  border-radius: 10px;
  border: 1px solid #979797;
  box-sizing: border-box;
  padding: 0 12px;
  background: rgba(0, 0, 0, 0);
  height: 40px;
  border-radius: none;
  
  box-sizing: border-box;
  
  font-weight: 400;
  font-size: 16px;
  color: #999999;
  line-height: 40px;
  
  display: block;
  width:48%;
  margin-top: 32rpx;
}
.order_mid_right{
  border-radius: 10px;
  border: 1px solid #979797;
  box-sizing: border-box;
  padding: 0 12px;
  background: rgba(0, 0, 0, 0);
  height: 40px;
  border-radius: none;
  
  box-sizing: border-box;
  
  font-weight: 400;
  font-size: 16px;
  color: #999999;
  line-height: 40px;
  
  display: block;
  width:48%;
  margin-top: 32rpx;
}
.reply{
  width: 100%;
  margin-top: 12px;
  box-sizing: border-box;
  padding: 18px 12px 18px 12px;
  border: 1px solid #8C8C8C;
  height: 200px;
  border-radius: 10px;
}
.btn {
  width: 300px;
  height: 52px;
  background: #018ecf;
  border-radius: 4px;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  margin: 40px auto;
}
.btn2{
  width: 300px;
  height: 52px;
  background: #aaacad;
  border-radius: 4px;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  margin: 40px  auto;
}
</style>