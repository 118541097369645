<template>
    <div>
     <div class="title">
      {{ $t('xjd.fl') }}
     </div>
     <!-- <van-tabbar route>
  <van-tabbar-item replace to="/" icon="wap-home-o">{{ $t('xjd.sy') }}</van-tabbar-item>
  <van-tabbar-item replace to="/classfication"  icon="apps-o">{{ $t('xjd.fl') }}</van-tabbar-item>
  <van-tabbar-item replace to="/shoppingCar" icon="shopping-cart-o">{{ $t('xjd.gwc') }}</van-tabbar-item>
  <van-tabbar-item replace to="/mine"  icon="contact-o">{{ $t('xjd.wd') }}</van-tabbar-item>
</van-tabbar> -->
    </div>
  </template>
      
  <script>
 
  export default {
    data() {
      return {
       
      }
    },
    methods: {
   
    },
    mounted() {
     
    },
  
  }
      </script>
      
      
    <style scoped>
  .title{
    width: 100%;
    text-align: center;
    font-size: 16px;
    margin-top: 11px;
  }
  </style>