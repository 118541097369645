<template>
  <div class="bg1">
    <div class="headerr before">
      <img :src="require('@/assets/closeee.png')" class="leftArrow"  @click="onClickLeft" style="top: 44px;">
      <img :src="require('@/assets/logo.png')" class="headerlogo">
    </div>
    <div style="padding:1px 16px 40px;border-radius: 20px 20px 0 0 ;margin-top: -20px;background-color: #ffffff;">
      <div style="font-size: 14px;color: #666666;margin-top: 30px;">
        {{ $t('logon.dl21') }}
        </div>
      
      <input type="text" class="flex-con m_b10"  v-model="user_company" :readonly="status == 1 ? true : false">
      <div style="font-size: 14px;color: #666666;margin-top: 12px;">
        {{ $t('logon.dl22') }}
        </div>
      <input type="text" class="flex-con m_b10"  v-model="user_position" :readonly="status == 1 ? true : false">
      <div style="font-size: 14px;color: #666666;margin-top: 12px;">
        {{ $t('logon.dl17') }}
        </div>
      <input type="text" class="flex-con m_b10"  v-model="user_name" :readonly="status == 1 ? true : false">
      <div style="font-size: 14px;color: #666666;margin-top: 12px;">
        {{ $t('logon.dl20') }}
        </div>
     
      <input type="text" class="flex-con m_b14"  v-model="user_phone" :readonly="status == 1 ? true : false">
      <div style="font-size: 14px;color: #666666;margin-top: 12px;">
        {{ $t('logon.dl19') }}
        </div>
      <input type="text" class="flex-con m_b10"  v-model="email" readonly>
      <!-- <input type="text" class="flex-con" :placeholder="$t('logon.dl20')" v-model="user_phone"
                :readonly="status == 1 ? true : false"> -->

      <div class="btn align-center font-fff-16" v-if="status==1">{{ $t('logon.dl23') }}</div>
      <div class="btn align-center font-fff-16" v-else-if="status==2" @click="submit()">{{ $t('logon.dl24') }}</div>
      <div class="btn align-center font-fff-16" v-else @click="submit()">{{ $t('logon.dl25') }}</div>
    </div>
    <van-popup v-model="show">
      <div class="dmiddle">
        <div class="dtop flex-wrap">
          <div class="flex-con">{{ $t('logon.dl26') }}</div>
          <img src="../assets/closeee.png" class="closeee"  @click="show=false">
        </div>
        <div class="dtitle flex-wrap">
          <div class="flex-con"></div>
          <img src="../assets/message.png" class="message">
          <div>{{ $t('logon.dl27') }}</div>
          <div class="flex-con"></div>
        </div>
        <div class="dinfo">
            {{user_refuse_reason}}
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { commitCheck, userBaseInfo } from '../api'
export default {
  data() {
    return {
      user_name: '',
      user_xing: '',
      email: '',
      user_phone: '',
      user_company: '',
      user_position: '',
      status: '',
      dialogVisible: false,
      user_refuse_reason: '',
      show: false
    }
  },
  methods: {
    submit() { 
      if (this.user_company == '') {
        this.$toast(this.$t('logon.dl21'));
        return;
      }
      if (this.user_position == '') {
        this.$toast(this.$t('logon.dl22'))
        return;
      }
      if (this.user_name == '') {
        this.$toast(this.$t('logon.dl17'))
        return;
      }
     
      if (this.user_phone == '') {
        this.$toast(this.$t('logon.dl20'))
        return;
      }
      
      
      commitCheck({
        user_id: this.$route.params.user_id,
        user_name: this.user_name,
        user_xing:'',
        user_phone: this.user_phone,
        user_company: this.user_company,
        user_position: this.user_position,
        user_email: this.email
      }).then((data) => {
        if (data.status == 200) {
          this.status = 1;
           this.$toast(this.$t('logon.dl28'))
        }
      })
    },
    initData() {
      userBaseInfo({
        user_id: this.$route.params.user_id,
      }).then((data) => {
        if (data.status == 200) {
          this.user_name = data.msg.user_name;
          this.user_phone = data.msg.user_phone;
          
          this.user_company = data.msg.user_company;
          this.user_position = data.msg.user_position;
          this.user_refuse_reason = data.msg.user_refuse_reason || '';
          this.dialogVisible = data.msg.user_status == 3 ? true : false;
        }
      })
    },
    onClickLeft() {
      this.$router.go(-1)
    },
  },
  mounted() {

    this.email = this.$route.params.email;
    if (this.$route.params.type == 1) {
      this.status = this.$route.params.status;
      this.show = this.status == 3 ? true : false;
      this.initData();
    }

  }
}
</script>


<style scoped>
.bg1 {
  width: 100%;
  height: 100vh;
  /* background: url("@/assets/bg1.png") no-repeat left top;
  background-size: 95% auto; */
  background-color: #ffffff;
}
input[type="text"],
input[type="password"],
input[type="tel"] {
  background: rgba(0, 0, 0, 0);
  height: 40px;
  border-radius: 4px;
  border: 1px solid #E1E1E1;
  box-sizing: border-box;
  padding: 0 12px;
  color: #333;
  font-size: 16px;
  outline: none;
  display: block;
  width: -webkit-fill-available;
  margin-top: 10px;
}

input[type="text"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder {
  color: #999;
}

.btn {
  height: 40px;
  background: linear-gradient(to bottom, #A6E3FF, #018ECF);
  border-radius: 27px;
  margin-top: 48px;
}
.dmiddle {
  font-size: 14px;
  font-weight: 400;
  color: #333;
  width: 230px;
  background: url("@/assets/bg2.png") no-repeat left top;
  background-size: 100% 100%;
  padding-bottom: 40px;
}
.dtop {
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  height: 44px;
  align-items: center;
  border: 1px solid #96dbfa;
  padding: 0 12px;
}
.closeee {
  width: 20px;
  height: 20px;
}
.message {
  width: 20px;
  height: 20px;
  margin-right: 11px;
}
.dtitle {
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  margin: 14px 0;
}
.dinfo {
  text-align: center;
}
.headerr{
    height: 200px;
    align-items: center;
    padding: 0 16px;
    
    line-height: 44px;
    background: linear-gradient(to bottom, #A6E3FF, #E0F3FF);
}
.headerlogo{
  display: block;
    width: 128px;
    height: 30px;
    margin:40px auto;
}
</style>