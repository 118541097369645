const Spanish = {
    hello: 'hello word spa',
    headSY: '首页1',
    headQYCP: '企业产品1',
    headGYWM: '关于我们1',
    headLXWM: '联系我们1',
    headYSZC: '隐私政策1',
    headSSCP: '搜索产品1',
    headGRZX: '个人中心1',
    headYY: '英语1',
    headXBYY: '西班牙语1',
    headPTYY: '葡萄牙语1',
    mainCPFL: '产品分类1',
    mainTJCP: '推荐产品1',
    mainTJCPYH: '为您推荐最新优惠产品1',
    mainCK: '查看1',
    footAll: {
        rmlb: '热门类别1',
        fwzc: '服务政策1',
        sjbhzc: '数据保护政策1',
        yszc: '隐私政策1',
        gywm: '关于我们1',
        gsjs: '公司介绍1',
        lxwm: '联系我们1',
        dz: '地址1',
        zb: '总部1',
        fwtk: '服务条款1'
    },
    headGRZXObj: {
        wd: '我的1',
        jbxx: '基本资料1',
        dzgl: '地址管理1',
        wdsc: '我的购买清单1',
        qbdd: '全部订单1',
        dqr: '待确认1',
        dfk: '待付款1',
        dfh: '待发货1',
        dsh: '待收货1',
        ywc: '已收货1',
        yqx: '已取消1',
        tczh: '退出登录1',
        zhzx: '账号中心1',
        ddzx: '订单中心1',
        gwc: '购物车1'
    },
    jbXX: {
        xing: '姓1',
        ming: '名1',
        dzyx: '电子邮箱1',
        gs: '公司1',
        zw: '职位1',
        dh: '电话1',
        dhhm: '电话号码1',
        tj: '添加1',
        mm: '密码1',
        ggmm: '更改密码1',
        ndqmm: '您当前密码1',
        ndxmm: '您的新密码1',
        cxsrndxmm: '重新输入您的新密码1',
        qsr: '请输入1',
        lcbyz: '两次新密码输入不一致1',
        zhsz: '帐户设置1',
        jbxx: '基本信息1',
        xm: '姓名1',
        genggai: '更改1',
        ggyz: '更改 / 验证1',
        yxdz: '邮箱地址1',
        yxqr: '邮箱确认1',
        qux: '取消1',
        qr: '确认1',
        bjxm: '编辑姓名1',
        dqmm: '当前密码1',
        xmm: '新密码1',
        qrxmm: '确认新密码1',
    },
    addressObj: {
        mo: '设置为默认地址1',
        xdz: '新地址1',
        xxdz: '详细地址1',
        yb: '邮编1',
        dzgl: '地址管理1',
        mrdz: '默认地址1',
        qr: '确认1',
        bjdz: '编辑地址1'
    },
    cateObj: {
        lb: '类别1',
        sx: '属性1'
    },
    goodsObj: {
        bz: '包装1',
        sl: '数量1',
        xzgg: '选择规格1',
        ljgm: '立即购买1',
        zj: '总计1',
        jrgwc: '加入购物车1',
        cpxq: '产品详情1',
        tjsc: '添加收藏1',
        qxsc: '取消收藏1',
    },
    carObj: {
        sc: '删除1',
        qx: '全选1',
        cpxx: '产品信息1',
        cpgg: '产品规格1',
        dj: '单价1',
        sl: '数量1',
        xj: '小计1',
        gwczj: '购物车总计1',
        je: '金额1',
        tj: '提交1',
        xz: '请选择删除的产品1',
        qrsc: '确认删除吗1',
        qux: '取消1',
        qr: '确认1',
        xzcp: '请选择产品1',
        tjdd: '提交订单1',
        cpqd: '产品清单1',
        tjdz: '添加地址1',
        qxzdz: '请选择地址1'
    },
    orderObj: {
        ddxx: '订单信息1',
        gg: '规格1',
        zt: '状态1',
        cz: '操作1',
        ddsj: '订单时间1',
        ddbh: '订单编号1',
        ckdd: '查看订单1',
        qrdd: '确认订单1',
        qxdd: '取消订单1',
        qrsh: '确认收货1',
        zlyd: '再来一单1',
        ddxq: '订单详情1',
        qm: '全名1',
        dh: '电话1',
        wl: '物流1',
        w: '无1',
        hwys: '货物运输1'
    },
    logon: {
        dl: '登录1',
        dl1: '探索摩尔化工1',
        dl2: '快速下单1',
        dl3: '直接对接 拥有最优惠价格1',
        dl4: '高效恢复1',
        dl5: '请输入电子邮箱1',
        dl6: '请输入密码1',
        dl7: '忘记密码1',
        dl8: '注册1',
        dl9: '请输入验证码1',
        dl10: '再次输入密码1',
        dl11: '发送验证码1',
        dl12: '验证码错误1',
        dl13: '两次密码不一致1',
        dl14: '发送成功1',
        dl15: '重新获取1',
        dl16: '完善信息1',
        dl17: '请输入名字1',
        dl18: '请输入姓氏1',
        dl19: '请输入邮箱1',
        dl20: '请输入电话1',
        dl21: '请输入公司1',
        dl22: '请输入职位1',
        dl23: '审核中1',
        dl24: '重新提交1',
        dl25: '提交1',
        dl26: '提示1',
        dl27: '审核失败1',
        dl28: '审核中，请耐心等待1',
        yx: '邮箱1',
        mm: '密码1',
        cz: '重置1',
        myzh: '没有账号1',
        agree: '继续，即表示您同意我们的1',
        jx: '继续1',
        xgdlmm: '修改登录密码1'
    },
    xjd: {
        xjd: '询价单',
        txxjd: '填写询价单',
        xjdlb: '询价单列表',
        xjcp: '询价产品',
        pthf: '平台回复',
        lxr: '联系人',
        lxrxx: '联系人电话',
        srlxr: '输入联系人',
        srlxrxx: '输入联系人电话',
        cpsl: '填写需要查询的产品及数量',
        myg:'贸易国1',
        cyg:'出运港1',
        mdg:'目的港1',
        ysfs:'支付方式1',
        mysysj:'贸易术语数据1',
        cgood:'选择产品1',
        xzgg:'选择规格1',
        xzbz:'选择包装1',
        txsl:'填写数量1',
        xzhb:'选择货币1',
        scdd:'生成订单',
        tishi:'提示1',
        qx:'取消1',
        cg:'确定1',
        tsxx:'此操作将永久删除该记录, 是否继续?1',
        sccg:'删除成功1',
        qxsc:'已取消删除1',
        ss:'搜索1',
        cygn:'常用功能1',
        sy:'首页1',
        fl:'分类1',
        gwc:'购物车1',
        wd:'我的1',
        xjdbh:'询价单编号1',
        cggsmc:'采购公司名称1',
        cggsdz:'采购公司地址1',
        cgyxm:'采购员姓名1',
        cgydh:'采购员电话1',
        yjdz:'邮件地址',
        dj:'单价1',
        zongjia:'总价1',
        dw:'单位1',
        txdw:'填写单位1',
        ddss:'到港时间1',
    },
    new:{
        qb:'全部',
        tip1:'是否确认订单',
        tip2:'是否取消订单',
        tip3:'是否确认收货',
        sx:'筛选'
    }
}
export default Spanish