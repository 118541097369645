<template>
  <div class="flex-wrap flex-vertical bg1" style="padding:0 16px 12px;">
    <div class="header before" style="padding: 0;">
      <img :src="require('@/assets/leftArrow.png')" class="leftArrow" style="left: 0;"  @click="toBack()">
      <div class="font-333-18-500 center" style="line-height: 44px;">{{ addr_id ? $t('addressObj.bjdz') : $t('addressObj.xdz') }}</div>
    </div>
    <div style="padding:12px 0;">
      <div class="input_out m_b10">
        <div class="font-666-14">{{$t('jbXX.ming')}}</div>
        <input type="text"  v-model="addressObj.addr_name">
      </div>
      <div class="input_out m_b10">
        <div class="font-666-14">{{$t('jbXX.xing')}}</div>
        <input type="text" v-model="addressObj.addr_xing">
      </div>
      <div class="input_out m_b10">
        <div class="font-666-14">{{$t('jbXX.gs')}}</div>
        <input type="text"  v-model="addressObj.addr_company">
      </div>
      <div class="input_out m_b10">
        <div class="font-666-14">{{$t('addressObj.xxdz')}}</div>
        <input type="text" v-model="addressObj.addr_address">
      </div>
      <div class="input_out m_b10">
        <div class="font-666-14">{{$t('addressObj.yb')}}</div>
        <input type="text" v-model="addressObj.addr_postal">
      </div>
      <div class="input_out m_b10">
        <div class="font-666-14">{{$t('jbXX.dhhm')}}</div>
        <input type="text" v-model="addressObj.addr_phone">
      </div>
      <div class="align-start font-666-14" style="margin: 12px 0 28px;">
        <input type="checkbox" name="checkbox" v-model="checked" />
        {{ $t('addressObj.mo') }}
      </div>
    </div>

    <div class="btn2 align-center font-fff-16"  @click="initCreateAddress">{{ addr_id ? $t('addressObj.qr') : $t('jbXX.tj') }}</div>
  </div>
</template>

<script>
import { createAddress, showAddress, updateAddress } from '../api'
export default {
  data() {
    return {
      addressObj: {},
      checked: false,
      addr_id: this.$route.params.addr_id
    }
  },
  methods: {
    initShowAddress() {
            let params = {
                addr_id: this.addr_id
            }
            showAddress(params).then((data) => {
                if (data.status == 200) {
                    this.addressObj = data.msg
                    this.checked = this.addressObj.addr_default == 1 ? true : false
                }
            })
        },
        initCreateAddress() {
            if (!this.addressObj.addr_name) {
                this.$toast(this.$t('jbXX.qsr') + this.$t('jbXX.ming'))
                return;
            }
            if (!this.addressObj.addr_xing) {
                this.$toast(this.$t('jbXX.qsr') + this.$t('jbXX.xing'))
                return;
            }
            if (!this.addressObj.addr_company) {
                this.$toast(this.$t('jbXX.qsr') + this.$t('jbXX.gs'))
                return;
            }
            if (!this.addressObj.addr_address) {
                this.$toast(this.$t('jbXX.qsr') + this.$t('addressObj.xxdz'))
                return;
            }
            if (!this.addressObj.addr_postal) {
                this.$toast(this.$t('jbXX.qsr') + this.$t('addressObj.yb'))
                return;
            }
            if (!this.addressObj.addr_phone) {
                this.$toast(this.$t('jbXX.qsr') + this.$t('jbXX.dhhm'))
                return;
            }
            let params = {
                addr_xing: this.addressObj.addr_xing || '',
                addr_name: this.addressObj.addr_name || '',
                addr_phone: this.addressObj.addr_phone || '',
                addr_postal: this.addressObj.addr_postal || '',
                addr_company: this.addressObj.addr_company || '',
                addr_address: this.addressObj.addr_address || '',
                addr_default: this.checked ? 1 : 2,
            }
            if (this.addr_id) {
                params.addr_id = this.addr_id
                updateAddress(params).then((data) => {
                    if (data.status == 200) {
                        this.$toast(data.msg)
                        setTimeout(() => {
                            this.$router.go(-1)
                        }, 800);
                    }
                })
                return
            }
            createAddress(params).then((data) => {
                if (data.status == 200) {
                    this.$toast(data.msg)
                    setTimeout(() => {
                        this.$router.go(-1)
                    }, 800);
                }
            })
        },
  },
  mounted() {
    if (this.addr_id) {
            this.initShowAddress()
        }
  },

}
</script>


<style scoped>
.bg1 {
  width: 100%;
  height: 100vh;
  background: url("@/assets/bg1.png") no-repeat left top;
  background-size: 95% auto;
}
.input_out {
  height: 54px;
  border-radius: 10px;
  border: 1px solid #979797;
  box-sizing: border-box;
  padding: 6px 12px;
}

#login {
  height: 100vh;
}

input[type="text"],
input[type="password"],
input[type="tel"] {
  background: rgba(0, 0, 0, 0);
  height: 22px;
  border-radius: none;
  border: none;
  box-sizing: border-box;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
  outline: none;
  display: block;
  width: -webkit-fill-available;
}

input[type="text"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder {
  color: #999;
}

.btn2 {
  height: 54px;
  background: #018ecf;
  border-radius: 27px;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  width: 24px;
  height: 24px;
  border: 2px solid #979797;
  border-radius: 1px;
  margin-right: 16px;
}

input[type="checkbox"]:checked {
  border: 2px solid #018ecf;
  background: url("@/assets/checked.png") no-repeat center center;
  background-size: 18px 18px;
}
</style>