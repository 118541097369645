<template>
  <div id="login" class="flex-wrap flex-vertical bg1">
    <div class="flex-con">
      <div class="headerr before">
        <img :src="require('@/assets/closeee.png')" class="leftArrow hidden">
        <img :src="require('@/assets/logo.png')" class="headerlogo">
      </div>
      <div style="padding:10px 16px 0;border-radius: 20px 20px 0 0 ;margin-top: -20px;background-color: #ffffff;">
        <div style="font-size: 14px;color: #666666;margin-top: 30px;">
        {{ $t('logon.yx') }}
        </div>
        <input type="text" v-model="email" class="m_b10"  >
        <div style="font-size: 14px;color: #666666;margin-top: 12px;">
          {{ $t('logon.mm') }}
        </div>
        <input type="text" v-model="pwd" class="m_b14" >
        <div class="font-999-14 m_b14" style="font-size: 14px;color: #999999;" @click="fnForgetPwd">{{ $t('logon.dl7') }}? <span class="color-018ECF">{{ $t('logon.cz')
                        }}</span>
        </div>
        <div class="btn align-center font-fff-16" @click="toLogin">{{ $t('logon.dl') }}</div>
      </div>

    </div>
    <div class="p_b14">
      <van-divider :style="{ borderColor: '#eee', margin: '14px 0' }" />
      <div class="foo">{{ $t('logon.myzh') }}? <br> <span class="color-018ECF" @click="fnRegister">{{ $t('logon.dl8') }}</span></div>
    </div>
  </div>
</template>

<script>
import { mrLogin } from '../api'
export default {
  data() {
    return {
      email: '',
      pwd: ''
    }
  },
  methods: {
    toLogin() {
      if (this.email == '') {
        this.$toast(this.$t('logon.dl5'))
        return;
      }
      if (this.pwd == '') {
        this.$toast(this.$t('logon.dl6'));
        return;
      }
      mrLogin({
        email: this.email,
        pwd: this.pwd,
      }).then((data) => {
        if (data.status == 200) {

          if (data.msg.user_type == 1) {
            localStorage.setItem('allUserMrh5', JSON.stringify(data.msg))
            this.$router.push({ name: 'Main' })
          } else {
            this.$router.push({
              name: 'completeData',
              params: { type: 1, email: data.msg.user_email, status: data.msg.user_status, user_id: data.msg.user_id }
            })
          }

        }
      })

    },
    fnRegister() {
      this.$router.push({ name: 'register', params: {} })
    },
    fnForgetPwd() {
      this.$router.push({
        name: 'forgetPwd'
      })
    }
  },
  mounted() {

  },

}
</script>


<style scoped>
.bg1 {
  width: 100%;
  height: 100vh;
  /* background: url("@/assets/bg1.png") no-repeat left top;
  background-size: 95% auto; */
  background-color: #ffffff;
}
.foo {
  font-weight: 400;
  font-size: 16px;
  color: #999999;
  line-height: 30px;
  text-align: center;
}

#login {
  height: 100vh;
}

input[type="text"],
input[type="password"],
input[type="tel"] {
  background: rgba(0, 0, 0, 0);
  height: 40px;
  border-radius: 4px;
  border: 1px solid #E1E1E1;
  box-sizing: border-box;
  padding: 0 12px;
  color: #333;
  font-size: 16px;
  outline: none;
  display: block;
  width: -webkit-fill-available;
  margin-top: 10px;
}

input[type="text"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder {
  color: #999;
}

.btn {
  height: 40px;
  background: linear-gradient(to bottom, #A6E3FF, #018ECF);
  border-radius: 27px;
}
.headerr{
    height: 200px;
    align-items: center;
    padding: 0 16px;
    
    line-height: 44px;
    background-image: url(@/assets/bg.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.headerlogo{
  display: block;
    width: 128px;
    height: 30px;
    margin:40px auto;
}
</style>