<template>
  <div id="inquiryList" class="flex-wrap flex-vertical bg1" style="padding:0 16px 12px;">
    <div class="header before" style="padding: 0;">
      <img :src="require('@/assets/leftArrow.png')" class="leftArrow" style="left: 0;"  @click="toBack()">
      <div class="font-333-18-500 center" style="line-height: 44px;">{{ $t('xjd.xjdlb') }}</div>
    </div>
    <div class="title flex-wrap"  v-for="(item, index) in list" :key="index" @click="fnDetail(item.order_id, item.order_xjd_read)">
        <div class="flex-con">{{ item.order_xjd_gs_name || '' }}-{{ item.order_xjd_cyg || '' }}-{{ item.order_xjd_mdg || '' }}</div>
        <div class="red" v-show="item.order_xjd_read == 1"></div>
        <img :src="require('@/assets/arrow3.png')" class="arrow">
    </div>
  </div>
</template>

<script>
import {  alreadyRead, getXunList} from '../api'
export default {
  data() {
    return {
      page: 1,
      count: 0,
      list: [],
      loading: true,
      finished: false,
      refreshing: false,
      order_xjd_myg: '',
      order_xjd_cyg: '',
      order_xjd_mdg: '',
    }
  },
  methods: {
    fnDetail(idd,status) {
      if(status==1){
        alreadyRead({
          order_id: idd
        }).then((data) => {
          if (data.status == 200) {
            this.$router.push({
              name: 'inquiryXq',
              params: {
                id: idd
              },
            })
          }
        })
      }else{
            this.$router.push({
              name: 'inquiryXq',
              params: {
                id: idd
              },
            })
      }
      
      
    },
    initData() {
      getXunList({
        page: this.page
      }).then((data) => {
        if (data.status == 200) {
          this.count = data.count;
          if (this.refreshing) {
            this.list = data.msg
            this.refreshing = false
            if (this.list.length >= this.count) {
              this.finished = true
            }
            return
          }
          this.list = this.list.concat(data.msg)
          this.loading = false
          if (this.list.length >= this.count) {
            this.finished = true
          }
        }
      })
    },
    onLoad() {
      this.page++
      this.initData()
    },
    onRefresh() {
      this.finished = false
      this.loading = true
      this.page = 1
      this.initData()
    },
  },
  mounted() {
    this.initData()
  },

}
</script>


<style scoped>
.bg1 {
  width: 100%;
  height: 100vh;
  background: url("@/assets/bg1.png") no-repeat left top;
  background-size: 95% auto;
}
.title{
    padding:0 12px;
    height: 44px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    align-items: center;
    margin-top: 10px;
}
.arrow{
    width: 24px;
    height: 24px;
}
.red{
    width: 4px;
    height: 4px;
    background: #FF0000;
    border-radius: 50%;
    margin: 0 8px;
}
</style>