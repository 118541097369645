<template>
    <div>
      <div class="header before">
        <img :src="require('@/assets/leftArrow.png')" class="leftArrow"  @click="toBack()">
        <div class="hearderTitle">classification</div>
      </div>
      <van-dropdown-menu>
  <van-dropdown-item v-model="value1" :options="option1" />
  <van-dropdown-item v-model="value2" :options="option2" />
</van-dropdown-menu>
      <div class="top" style="height:30px;margin-top:7px;">
        <div class="top1 flex-wrap">
            <div class="label"  @click="fnSelect(item.cate_id)"  :class="{ label_active: fCate_id == item.cate_id || (fCate_id=='' && index==0)}" v-for="(item, index) in firstCate">{{$i18n.locale == 'en' ? item.cate_name_en: $i18n.locale == 'es' ? item.cate_name_es : item.cate_name_tp }}</div>
        </div>
      </div>
      <van-empty style="margin: auto" :description="$t('new.no1')" v-if="item.length == 0" />
      <div v-for="(item, index) in item">
        
        <div class="middle flex-wrap"  @click="fnSort(item.cate_id)">
          <div class="flex-con">{{$i18n.locale == 'en' ? item.cate_name_en: $i18n.locale == 'es' ? item.cate_name_es : item.cate_name_tp }}</div>
          <img :src="require('@/assets/arrow.png')" class="arrow">
        </div>
        <div class="box">
            <div class="goods" v-for="(item, index) in item.goods"  @click="fnGoodsDetails(item.goods_id)">
                <img :src="require('@/assets/static/p4.png')" class="goodsImg">
                <div class="title aui-ellipsis-2">{{$i18n.locale == 'en' ? item.goods_name_en: $i18n.locale == 'es' ? item.goods_name_es : item.goods_name_tp }}</div>
                <div class="price">$ {{ item.goods_cu_price }}</div>
            </div>
        </div>

    </div>
  
    </div>
  </template>
    
  <script>
  import { cateGoodsForH5 } from '../api'
  export default {
    data() {
      return {
        fCate_id:this.$route.params.id||'',
        firstCate:[],
        item:[],
        value1: 0,
      value2: 'a',
      option1: [
        { text: '全部商品', value: 0 },
        { text: '新款商品', value: 1 },
        { text: '活动商品', value: 2 },
      ],
      option2: [
        { text: '默认排序', value: 'a' },
        { text: '好评排序', value: 'b' },
        { text: '销量排序', value: 'c' },
      ],
      }
    },
    methods: {
      initData() {
        let params = {
          fCate_id:this.fCate_id
        }
        cateGoodsForH5(params).then((data) => {
          if (data.status == 200) {
            this.firstCate = data.firstCate;
            this.item=data.item;
            this.value1=2;
          }
        })
      },
      fnSelect(id){
        this.fCate_id=id;
        let params = {
          fCate_id:this.fCate_id
        }
        cateGoodsForH5(params).then((data) => {
          if (data.status == 200) {
            this.$router.replace({ name: 'sortTwo', params: {id:this.fCate_id} });
            this.item=data.item;
          }
        })
      },
      fnSort(id) {
      this.$router.push({name: 'sortThree',params:{firstId:this.fCate_id,secondId:id}})
      },
    },
    mounted() {
      this.initData()
    },
  
  }
    </script>
    
    
  <style scoped>
 
  .box{
      padding: 0 16px;
  }
  .goods{
      display: inline-block;
      width:calc((100% - 10px)/2);
      background: #F8F8F8;
      border-radius: 4px;
      padding: 14px 10px;
      margin-bottom: 10px;
  }
  .goods:nth-child(2n+1){
      margin-right:10px;
  }
  .goodsImg{
      width: 100%;
      height:138px;
      margin-bottom: 10px;
  }
  .title{
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 16px;
      margin-bottom: 6px;
  }
  .price{
      font-weight: 500;
      font-size: 16px;
      color: #333333;
  }
  .top {
  width: 100%;
  height: 44px;
  overflow: hidden;
  overflow-x: scroll;
}
.top::-webkit-scrollbar {
  height: 0;
}
.top1 {
  width: fit-content;
  padding-left: 16px;
}
.label{
    width: max-content;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    margin-right: 16px;
}
.label_active{
    font-weight: 500;
    font-size: 16px;
    color: #018ECF;
}
.middle{
    padding: 0 16px;
    font-weight: 500;
    font-size: 14px;
    color: #333333;
    height:40px;
    align-items: center;
}
.arrow{
    width: 20px;
    height: 20px;
}
  </style>