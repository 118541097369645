<template>
  <div>
    <div class="header before" >
      <img :src="require('@/assets/leftArrow.png')" class="leftArrow"  @click="toBack()">
      <div class="hearderTitle">{{ $t('mainCPFL') }}</div>
    </div>
    <van-dropdown-menu active-color="#018ECF">
      <van-dropdown-item v-model="value1" :options="firstCate" @change="selectOne"/>
      <van-dropdown-item v-model="value2" :options="item" @change="selectTwo" v-if="item.length!=1 && value1!=0" />
    </van-dropdown-menu>
    <div class="top" style="height:30px;margin-top:8px;" v-if="value2!=0">
      <div class="top1 flex-wrap"> 
        <div class="label" :class="{ label_active: value3 == 0}"  @click.stop="selectThree(0)">{{ $t('new.qb') }}</div>
        <div class="label" :class="{ label_active: value3 == item.cate_id}" v-for="(item, index) in thirdCate" @click.stop="selectThree(item.cate_id)">{{$i18n.locale == 'en' ? item.cate_name_en: $i18n.locale == 'es' ? item.cate_name_es : item.cate_name_tp }}</div>
      </div>
    </div>
    <div class="box">

      <van-empty style="margin: auto" :description="$t('new.no1')" v-if="list.length == 0" />
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" finished-text="" @load="onLoad">
        <div class="goods" v-for="(item, index) in list"  @click="fnGoodsDetails(item.goods_id)">
              <img :src="require('@/assets/static/p4.png')" class="goodsImg">
              <div class="title aui-ellipsis-2">{{$i18n.locale == 'en' ? item.goods_name_en: $i18n.locale == 'es' ? item.goods_name_es : item.goods_name_tp }}</div>
              <div class="price">$ {{ item.goods_cu_price }}</div>
          </div>

    </van-list>
    </van-pull-refresh>

    </div>
    <van-tabbar   route>
      <van-tabbar-item replace to="/" icon="wap-home-o">{{ $t('xjd.sy') }}</van-tabbar-item>
  <van-tabbar-item replace to="/sortThree/0"  icon="apps-o">{{ $t('xjd.fl') }}</van-tabbar-item>
  <van-tabbar-item replace to="/shoppingCar" icon="shopping-cart-o">{{ $t('xjd.gwc') }}</van-tabbar-item>
  <van-tabbar-item replace to="/mine"  icon="contact-o">{{ $t('xjd.wd') }}</van-tabbar-item>
</van-tabbar>
  </div>
</template>
    
<script>
import { cateGoodsForH5,getContentBySecondCate,getContentByThirdCate } from '../api'
export default {
  data() {
    return {
      fCate_id:this.$route.params.id||0,
      firstCate:[],
      item:[],
      thirdCate:[],
      page: 1,
      count: 0,
      list: [],
      loading: true,
      finished: false,
      refreshing: false,
      value1: 0,
      value2: 0,
      value3:0,
      option1: { 
          "cate_id":0,
			    "cate_name_en": this.$t('new.qb'),
			    "cate_name_es": this.$t('new.qb'),
			    "cate_name_tp": this.$t('new.qb')
      },
      option2:{ 
          "cate_id":0,
			    "cate_name_en": this.$t('new.qb'),
			    "cate_name_es": this.$t('new.qb'),
			    "cate_name_tp": this.$t('new.qb')
      },
     active:1,
      
    }
  },
  methods: {
    selectOne(e){
      this.value1=e;
      this.fCate_id=e;
      if(e==0){
        this.value2=0;
      }
      this.page=1;
      this.list=[];
      this.initData(1);
    },
    selectTwo(e){
      this.value2=e;
      this.page=1;
      this.list=[];
      getContentBySecondCate({
        page: this.page,
        fCate_id:this.fCate_id,
        sCate_id:this.value2
      }).then((data) => {
        if (data.status == 200) {
          this.count = data.count;
          this.thirdCate = data.thirdCate;  
          if (this.refreshing) {
            this.list = data.goods
            this.refreshing = false
            if (this.list.length >= this.count) {
              this.finished = true
            }
            return
          }
          this.list = this.list.concat(data.goods)
          this.loading = false
          if (this.list.length >= this.count) {
            this.finished = true
          }
        }
      })
    },
    selectThree(id){
      this.value3=id;
      this.page=1;
      this.list=[];
      getContentByThirdCate({
        page: this.page,
        fCate_id:this.fCate_id,
        sCate_id:this.value2,
        tCate_id:id
      }).then((data) => {
        if (data.status == 200) {
          this.count = data.count; 
          if (this.refreshing) {
            this.list = data.goods
            this.refreshing = false
            if (this.list.length >= this.count) {
              this.finished = true
            }
            return
          }
          this.list = this.list.concat(data.goods)
          this.loading = false
          if (this.list.length >= this.count) {
            this.finished = true
          }
        }
      })
    },
    initData(type) {
      cateGoodsForH5({
        page: this.page,
        fCate_id:this.fCate_id
      }).then((data) => {
        if (data.status == 200) {
          this.count = data.count;
          this.firstCate = data.firstCate;
          this.firstCate.unshift(this.option1);
          if(this.fCate_id!='' && this.fCate_id!=0){
            this.item=data.item||[];
          }   
          this.item.unshift(this.option2);
          if(this.$i18n.locale=='English'){
            this.firstCate = this.firstCate.map(item=>({
              value:item.cate_id,
              text:item.cate_name_en,
            }))
            this.item = this.item.map(item=>({
              value:item.cate_id,
              text:item.cate_name_en,
            }))
          }else if(this.$i18n.locale=='Español'){
            this.firstCate = this.firstCate.map(item=>({
              value:item.cate_id,
              text:item.cate_name_es,
            }))
            this.item = this.item.map(item=>({
              value:item.cate_id,
              text:item.cate_name_es,
            }))
          }else{
            this.firstCate = this.firstCate.map(item=>({
              value:item.cate_id,
              text:item.cate_name_tp,
            }))
            this.item = this.item.map(item=>({
              value:item.cate_id,
              text:item.cate_name_tp,
            }))
          }
          console.log(JSON.stringify(this.firstCate));
          console.log(JSON.stringify(this.item));
          if(!type){
            this.value1=Number(this.fCate_id);
            this.value2=0;
          }else{
            // this.$router.replace({ name: 'sortThree', params: {id:this.fCate_id} });
          }
          
          if (this.refreshing) {
            this.list = data.goods
            this.refreshing = false
            if (this.list.length >= this.count) {
              this.finished = true
            }
            return
          }
          this.list = this.list.concat(data.goods)
          this.loading = false
          if (this.list.length >= this.count) {
            this.finished = true
          }
        }
      })
    },
    onLoad() {
      this.page++
      this.initData()
    },
    onRefresh() {
      this.finished = false
      this.loading = true
      this.page = 1
      this.initData()
    },
  },
  mounted() {
    this.initData()
  },

}
    </script>
    
    
  <style scoped>
.box {
  padding: 0 16px 100px 16px;
}
.goods {
  display: inline-block;
  width: calc((100% - 10px) / 2);
  background: #f8f8f8;
  border-radius: 4px;
  padding: 14px 10px;
  margin-bottom: 10px;
}
.goods:nth-child(2n + 1) {
  margin-right: 10px;
}
.goodsImg {
  width: 100%;
  height: 138px;
  margin-bottom: 10px;
}
.title {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 16px;
  margin-bottom: 6px;
}
.price {
  font-weight: 500;
  font-size: 16px;
  color: #333333;
}
.top {
  width: 100%;
  height: 44px;
  overflow: hidden;
  overflow-x: scroll;
}
.top::-webkit-scrollbar {
  height: 0;
}
.top1 {
  width: fit-content;
  padding-left: 16px;
  align-items: baseline;
}
.label {
  width: max-content;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  margin-right: 16px;
}
.label_active {
  font-weight: 500;
  font-size: 16px;
  color: #018ecf;
}
::v-deep .van-dropdown-menu__bar{
    box-shadow:none;
}
::v-deep .van-dropdown-menu__title{
    font-size: 16px;
    color: #333;
}
::v-deep .van-dropdown-menu__item{
    -webkit-flex: inherit;
    padding: 0 16px;
}
</style>