import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/views/main.vue'
import search from '@/views/search.vue'
import sortOne from '@/views/sortOne.vue'
import sortTwo from '@/views/sortTwo.vue'
import sortThree from '@/views/sortThree.vue'
import shoppingCar from '@/views/shoppingCar.vue'
import submit from '@/views/submit.vue'
import login from '@/views/login.vue'
import register from '@/views/register.vue'
import completeData from '@/views/completeData.vue'
import accountSetting from '@/views/accountSetting.vue'
import changeEmail from '@/views/changeEmail.vue'
import changeName from '@/views/changeName.vue'
import changeCompany from '@/views/changeCompany.vue'
import changePwd from '@/views/changePwd.vue'
import changePhone from '@/views/changePhone.vue'
import order from '@/views/order.vue'
import orderDetail from '@/views/orderDetail.vue'
import goodsDetail from '@/views/goodsDetail.vue'
import inquiryList from '@/views/inquiryList.vue'
import inquirySheet from '@/views/inquirySheet.vue'
import addressList from '@/views/addressList.vue'
import addAddress from '@/views/addAddress.vue'
import collect from '@/views/collect.vue'
import policy from '@/views/policy.vue'
import aboutUs from '@/views/aboutUs.vue'
import contactUs from '@/views/contactUs.vue'
import forgetPwd from '@/views/forgetPwd.vue'
import inquiryXq from '@/views/inquiryXq.vue'
import mine from '@/views/mine.vue'
import classfication from '@/views/classfication.vue'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const routes = [
  {
    path: '',
    name: 'Main',
    component: Main,
    meta:{
      requiresAuth: true,
      keepAlive:true
    }
  },
  {
    path: '/search',
    name: 'search',
    component: search,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/sortOne',
    name: 'sortOne',
    component: sortOne,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/sortTwo/:id',
    name: 'sortTwo',
    component: sortTwo,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/sortThree/:id',
    name: 'sortThree',
    component: sortThree,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/shoppingCar',
    name: 'shoppingCar',
    component: shoppingCar,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/submit/:type/:sc_ids?/:goods_id?/:packaging_id?/:spec_id?/:goods_quantity?',
    name: 'submit',
    component: submit,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: login,
  },
  {
    path: '/register',
    name: 'register',
    component: register,
  },
  {
    path: '/completeData/:type/:email/:status?/:user_id?',
    name: 'completeData',
    component: completeData,
  },
  {
    path: '/accountSetting',
    name: 'accountSetting',
    component: accountSetting,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/changeEmail/:email',
    name: 'changeEmail',
    component: changeEmail,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/changeName/:user_name/:user_xing',
    name: 'changeName',
    component: changeName,
    meta:{
      requiresAuth: true
    }
  },
  
  {
    path: '/changeCompany/:user_company/:user_position',
    name: 'changeCompany',
    component: changeCompany,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/changePwd',
    name: 'changePwd',
    component: changePwd,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/changePhone/:user_phone',
    name: 'changePhone',
    component: changePhone,
    meta:{
      requiresAuth: true
    }
  },{
    path: '/order/:status',
    name: 'order',
    component: order,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/orderDetail/:order_id',
    name: 'orderDetail',
    component: orderDetail,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/goodsDetail/:id',
    name: 'goodsDetail',
    component: goodsDetail,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/inquiryList',
    name: 'inquiryList',
    component: inquiryList,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/inquirySheet',
    name: 'inquirySheet',
    component: inquirySheet,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/addAddress/:addr_id?',
    name: 'addAddress',
    component: addAddress,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/addressList/:type',
    name: 'addressList',
    component: addressList,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/collect',
    name: 'collect',
    component: collect,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/policy',
    name: 'policy',
    component: policy,
  },
  {
    path: '/aboutUs/:type',
    name: 'aboutUs',
    component: aboutUs,
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: contactUs,
  },
  {
    path: '/forgetPwd',
    name: 'forgetPwd',
    component: forgetPwd,
  },
  {
    path: '/inquiryXq/:id',
    name: 'inquiryXq',
    component: inquiryXq,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/mine',
    name: 'mine',
    component: mine,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/classfication',
    name: 'classfication',
    component: classfication,
    meta:{
      requiresAuth: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let allUserMr = localStorage.getItem('allUserMrh5') // 这里应该是判断用户是否登录的逻辑，例如检查 token 或者 cookie
  if (to.matched.some(record => record.meta.requiresAuth) && !allUserMr) {
    next({ name: 'login' }) // 如果用户未登录，跳转到登录页
  } else {
    next() // 如果用户已登录，或者不需要登录，继续
  }
})
export default router
