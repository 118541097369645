const Portuguese = {
    hello: 'hello word por',
    headSY: '首页2',
    headQYCP: '企业产品2',
    headGYWM: '关于我们2',
    headLXWM: '联系我们2',
    headYSZC: '隐私政策2',
    headSSCP: '搜索产品2',
    headGRZX: '个人中心2',
    headYY: '英语2',
    headXBYY: '西班牙语2',
    headPTYY: '葡萄牙语2',
    mainCPFL: '产品分类2',
    mainTJCP: '推荐产品2',
    mainTJCPYH: '为您推荐最新优惠产品2',
    mainCK: '查看2',
    footAll: {
        rmlb: '热门类别2',
        fwzc: '服务政策2',
        sjbhzc: '数据保护政策2',
        yszc: '隐私政策2',
        gywm: '关于我们2',
        gsjs: '公司介绍2',
        lxwm: '联系我们2',
        dz: '地址2',
        zb: '总部2',
        fwtk: '服务条款2'
    },
    headGRZXObj: {
        wd: '我的2',
        jbxx: '基本资料2',
        dzgl: '地址管理2',
        wdsc: '我的购买清单2',
        qbdd: '全部订单2',
        dqr: '待确认2',
        dfk: '待付款2',
        dfh: '待发货2',
        dsh: '待收货2',
        ywc: '已收货2',
        yqx: '已取消2',
        tczh: '退出登录2',
        zhzx: '账号中心2',
        ddzx: '订单中心2',
        gwc: '购物车2'
    },
    jbXX: {
        xing: '姓2',
        ming: '名2',
        dzyx: '电子邮箱2',
        gs: '公司2',
        zw: '职位2',
        dh: '电话2',
        dhhm: '电话号码2',
        tj: '添加2',
        mm: '密码2',
        ggmm: '更改密码2',
        ndqmm: '您当前密码2',
        ndxmm: '您的新密码2',
        cxsrndxmm: '重新输入您的新密码2',
        qsr: '请输入2',
        lcbyz: '两次新密码输入不一致2',
        zhsz: '帐户设置2',
        jbxx: '基本信息2',
        xm: '姓名2',
        genggai: '更改2',
        ggyz: '更改 / 验证2',
        yxdz: '邮箱地址2',
        yxqr: '邮箱确认2',
        qux: '取消2',
        qr: '确认2',
        bjxm: '编辑姓名2',
        dqmm: '当前密码2',
        xmm: '新密码2',
        qrxmm: '确认新密码2',
    },
    addressObj: {
        mo: '设置为默认地址2',
        xdz: '新地址2',
        xxdz: '详细地址2',
        yb: '邮编2',
        dzgl: '地址管理2',
        mrdz: '默认地址2',
        qr: '确认2',
        bjdz: '编辑地址2'
    },
    cateObj: {
        lb: '类别2',
        sx: '属性2'
    },
    goodsObj: {
        bz: '包装2',
        sl: '数量2',
        xzgg: '选择规格2',
        ljgm: '立即购买2',
        zj: '总计2',
        jrgwc: '加入购物车2',
        cpxq: '产品详情2',
        tjsc: '添加收藏2',
        qxsc: '取消收藏2',
    },
    carObj: {
        sc: '删除2',
        qx: '全选2',
        cpxx: '产品信息2',
        cpgg: '产品规格2',
        dj: '单价2',
        sl: '数量2',
        xj: '小计2',
        gwczj: '购物车总计2',
        je: '金额2',
        tj: '提交2',
        xz: '请选择删除的产品2',
        qrsc: '确认删除吗2',
        qux: '取消2',
        qr: '确认2',
        xzcp: '请选择产品2',
        tjdd: '提交订单2',
        cpqd: '产品清单2',
        tjdz: '添加地址2',
        qxzdz: '请选择地址2'
    },
    orderObj: {
        ddxx: '订单信息2',
        gg: '规格2',
        zt: '状态2',
        cz: '操作2',
        ddsj: '订单时间2',
        ddbh: '订单编号2',
        ckdd: '查看订单2',
        qrdd: '确认订单2',
        qxdd: '取消订单2',
        qrsh: '确认收货2',
        zlyd: '再来一单2',
        ddxq: '订单详情1',
        qm: '全名2',
        dh: '电话2',
        wl: '物流2',
        w: '无2',
        hwys: '货物运输2'
    },
    logon: {
        dl: '登录2',
        dl1: '探索摩尔化工2',
        dl2: '快速下单2',
        dl3: '直接对接 拥有最优惠价格2',
        dl4: '高效恢复2',
        dl5: '请输入电子邮箱2',
        dl6: '请输入密码2',
        dl7: '忘记密码2',
        dl8: '注册2',
        dl9: '请输入验证码2',
        dl10: '再次输入密码2',
        dl11: '发送验证码2',
        dl12: '验证码错误2',
        dl13: '两次密码不一致2',
        dl14: '发送成功2',
        dl15: '重新获取2',
        dl16: '完善信息2',
        dl17: '请输入名字2',
        dl18: '请输入姓氏2',
        dl19: '请输入邮箱2',
        dl20: '请输入电话2',
        dl21: '请输入公司2',
        dl22: '请输入职位2',
        dl23: '审核中2',
        dl24: '重新提交2',
        dl25: '提交2',
        dl26: '提示2',
        dl27: '审核失败2',
        dl28: '审核中，请耐心等待2',
        yx: '邮箱2',
        mm: '密码2',
        cz: '重置2',
        myzh: '没有账号2',
        agree: '继续，即表示您同意我们的2',
        jx: '继续2',
        xgdlmm: '修改登录密码2'
    },
    xjd: {
        xjd: '询价单',
        txxjd: '填写询价单',
        xjdlb: '询价单列表',
        xjcp: '询价产品',
        pthf: '平台回复',
        lxr: '联系人',
        lxrxx: '联系人电话',
        srlxr: '输入联系人',
        srlxrxx: '输入联系人电话',
        cpsl: '填写需要查询的产品及数量',
        myg:'贸易国2',
        cyg:'出运港2',
        mdg:'目的港2',
        ysfs:'支付方式2',
        mysysj:'贸易术语数据2',
        cgood:'选择产品2',
        xzgg:'选择规格2',
        xzbz:'选择包装2',
        txsl:'填写数量2',
        xzhb:'选择货币2',
        scdd:'生成订单2',
        tishi:'提示2',
        qx:'取消2',
        cg:'确定2',
        tsxx:'此操作将永久删除该记录, 是否继续?2',
        sccg:'删除成功2',
        qxsc:'已取消删除2',
        ss:'搜索2',
        cygn:'常用功能2',
        sy:'首页2',
        fl:'分类2',
        gwc:'购物车2',
        wd:'我的2',
        xjdbh:'询价单编号2',
        cggsmc:'采购公司名称2',
        cggsdz:'采购公司地址2',
        cgyxm:'采购员姓名2',
        cgydh:'采购员电话2',
        yjdz:'邮件地址',
        dj:'单价2',
        zongjia:'总价2',
        dw:'单位2',
        txdw:'填写单位2',
        ddss:'到港时间2',
    },
    new:{
        qb:'全部',
        tip1:'是否确认订单',
        tip2:'是否取消订单',
        tip3:'是否确认收货',
        sx:'筛选'
    }
}
export default Portuguese