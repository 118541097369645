<template>
  <div style="padding-bottom:68px;">
    <div class="header before" style="border-bottom: 1px solid #E2E2E2;">
      <img :src="require('@/assets/leftArrow.png')" class="leftArrow"  @click="toBack()">
      <div class="hearderTitle">{{ data.order_status == 1 ? $t('headGRZXObj.dqr') :
                  data.order_status == 2 ?$t('headGRZXObj.dfk') : 
                  data.order_status == 3 ? $t('headGRZXObj.dfh') :
                  data.order_status == 4 ?$t('headGRZXObj.dsh') :
                  data.order_status == 5 ? $t('headGRZXObj.ywc') :
                  $t('headGRZXObj.yqx') }}</div>
    </div>
    <div class="box">
      <div class="name">{{ $t('orderObj.ddxx') }}</div>
      <div class="card after" style="padding-bottom:0;">
        <div class="flex-wrap" style="align-item:center;margin-bottom:8px;">
            <div class="time flex-con">{{ $t('orderObj.ddsj') }}</div>
            <div style="font-weight:500">{{ data.order_time }}</div>
          </div>
          <div class="flex-wrap" style="align-item:center;margin-bottom:8px;">
            <div class="time flex-con">{{ $t('orderObj.ddbh') }}</div>
            <div style="font-weight:500">#{{ data.order_number }}</div>
          </div>
      </div>
      <div class="name">{{ $t('footAll.dz') }}</div>
      <div class="card flex-wrap">
        <div class="flex-con">
          <div class="aname" style="font-weight: 500;margin-bottom:8px;">{{ data.addr_name || '' }} {{ data.addr_xing || '' }}</div>
          <div style="margin-bottom:8px;">{{ data.addr_address || '' }}</div>
          <div>{{ data.addr_phone || '' }}</div>
        </div>
      </div>
      <div class="name" v-show="data.order_status == 4 || data.order_status == 5">{{ $t('orderObj.wl') }}</div>
      <div class="wlCard flex-wrap"  v-show="data.order_status == 4 || data.order_status == 5">
        <img :src="require('@/assets/wl.png')" class="wl">
        <div>
          {{ data.order_wuLiu_company || '' }}{{ data.order_wuLiu_code || ''}} <br> {{ data.order_wuLiu_desc || '' }} 
        </div>
      </div>
      <div class="name">订单汇总</div>
      <div class="goods flex-wrap"  v-for="(item, index) in data.orderGoods" :key="index">
        <img :src="item.goods_thumb" class="goodsImg">
        <div class="before flex-con">
          <div class="title  flex-wrap">
            <div class="flex-con aui-ellipsis-2">{{ $i18n.locale == 'en'?item.goods_name_en: $i18n.locale == 'es' ? item.goods_name_es : item.goods_name_tp }}</div>
          </div>
          <div class="info">{{ item.packaging_name }}</div>
          <div class="info">{{ item.spec_value }}</div>
          <div class="price flex-wrap">
            <div class="flex-con">$ {{ item.of_price }}</div>
            <div class="num">X {{ item.of_quantity }}</div>
          </div>
        </div>
      </div>
      <div class="priceAll flex-wrap">
        <div class="priceLeft flex-con">{{ $t('goodsObj.zj') }}</div>
        <div>${{ data.order_total_price }}</div>
      </div>
    </div>
    <div class="bottom flex-wrap">
      <div class="btn btn1 flex-con" v-show="data.order_status == 1 || data.order_status == 2" @click.stop="fnSure(2,data.order_id)">{{ $t('orderObj.qxdd') }}</div>
      <div class="btn btn2 flex-con" v-show="data.order_status == 1" @click.stop="fnSure(1,data.order_id)">{{ $t('orderObj.qrdd') }}</div>
      <div class="btn btn2 flex-con" v-show="data.order_status == 4" @click.stop="fnSure(3,data.order_id)">{{ $t('orderObj.qrsh') }}</div>
      <div class="btn btn2 flex-con" v-show="data.order_status == 5 && data.orderGoods.length == 1" @click.stop="fnGoodsDetails(data.orderGoods[0].of_goods_id)">{{ $t('orderObj.zlyd') }}</div>
    </div>

    <van-popup v-model="show1" round>
      <div class="tipBox">
        <div style="color:#333;font-size:14px;margin-bottom: 20px">
          {{ts}}
        </div>
        <div class="align-between">
          <div class="tipBtn1 align-center" @click="fnCommit">{{ $t('carObj.qr') }}</div>
          <div class="tipBtn2 align-center"  @click="show1=false">{{ $t('carObj.qux') }}</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
      
<script>
import { orderDetail, sureOrder, overOrder, cancelOrder } from '../api'
export default {
  data() {
    return {
      data: {},
      ts:'',
      orderId:'',
      where:0,
      show1:false,
    }
  },
  methods: {
    initData() {
      let params = {
        order_id: this.$route.params.order_id
      }
      orderDetail(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
        }
      })
    },
    fnSure(type,id){
        this.show1=true;
        this.where=type;
        if(this.where==1){
            this.ts=this.$t('new.tip1');
        }else if(this.where==2){
            this.ts=this.$t('new.tip2');
        }else{
            this.ts=this.$t('new.tip3');
        }
        this.orderId=id;
    },
    fnCommit(){
        if(this.where==1){
            this.fnSureOrder(this.orderId);
        }else if(this.where==2){
            this.fnCancleOrder(this.orderId);
        }else{
            this.fnSureSh(this.orderId) 
        }
    },
    fnSureOrder(idd) {
      let params = {
        order_id: idd
      }
      sureOrder(params).then((data) => {
        if (data.status == 200) {
          this.$toast(data.msg)
          this.show1=false;
          this.initData()
        }
      })
    },
    fnCancleOrder(idd) {
      let params = {
        order_id: idd
      }
      cancelOrder(params).then((data) => {
        if (data.status == 200) {
          this.$toast(data.msg)
          this.show1=false;
          this.initData()
        }
      })
    },
    fnSureSh(idd) {
      let params = {
        order_id: idd
      }
      overOrder(params).then((data) => {
        if (data.status == 200) {
          this.$toast(data.msg)
          this.show1=false;
          this.initData()
        }
      })
    },
  },
  mounted() {
    this.initData()
  },

}
  </script>
  <style scoped>
.box {
  padding: 0 16px 50px;
  /* margin-bottom: 78px; */
}
.goods {
  padding-bottom: 10px;
  border-bottom: 1px solid #e3e3e3;
  margin-top: 10px;
}
.goodsImg {
  width: 138px;
  height: 138px;
  margin-right: 10px;
}
.title {
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  line-height: 16px;
  align-items: center;
  margin-top: 5px;
}

.info {
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  margin-top: 6px;
}
.price {
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  align-items: center;
  margin-top: 16px;
}
.num {
  font-size: 14px;
}
.name {
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  margin-top: 12px;
}
.card {
  background: #f8f9fc;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  line-height: 20px;
  align-items: center;
}
.arrow {
  width: 24px;
  height: 24px;
}
.priceAll {
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  padding: 10px 0;
}
.priceLeft {
  color: #666666;
}
.bottom {
  padding: 12px 16px;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background: #fff;
}
.btn1 {
  padding: 0 10px;
  height: 44px;
  border-radius: 31px;
  border: 1px solid #018ecf;
  font-weight: 400;
  font-size: 14px;
  color: #018ecf;
  line-height: 44px;
  text-align: center;
}
.btn2 {
  padding: 0 10px;
  height: 44px;
  background: #018ecf;
  border-radius: 31px;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  line-height: 44px;
  text-align: center;
}
.btn:nth-child(2n+2){
    margin-left: 11px;
}
.time{
    color: #666666;
}
.status{
    background: #F8F9FC;
    border-radius: 4px;
    padding: 10px 26px 14px;
}
.wlCard{
    padding: 6px 8px;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    line-height: 20px;
    background: #F8F9FC;
    border-radius: 4px;
    margin-top: 10px;
}
.wl{
    width: 24px;
    height:24px;
    margin-right: 8px;
    position: relative;
    top:-2px;
}
.tipBox {
  width: 275px;
  height: 105px;
  background: #ffffff;
  border-radius: 4px;
  padding: 20px;
  background: #fff;
  text-align: center;
  box-sizing: border-box;
}

.tipBtn1 {
  width: 110px;
  height: 25px;
  border-radius: 4px;
  border: 1px solid #018ecf;
  color:#018ecf;
}

.tipBtn2 {
  width: 110px;
  height: 25px;
  background:#018ecf;
  color:#fff;
  border-radius: 4px;
}
</style>