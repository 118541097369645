<template>
  <div id="login" class="flex-wrap flex-vertical bg1" style="padding:0 16px 12px;">
    <div class="flex-con">
      <div class="header before" style="padding: 0;">
        <img :src="require('@/assets/leftArrow.png')" class="leftArrow" style="left: 0;" @click="toBack()">
        <div class="font-333-18-500 center" style="line-height: 44px;">{{ $t('jbXX.bjxm') }}</div>
      </div>
      <div style="padding:12px 0;">
        <div class="input_out m_b10">
          <div class="font-666-14">{{ $t('jbXX.ming') }}</div>
          <input type="text" v-model="user_name">
        </div>
       
      </div>

    </div>
    <div class="btn1 align-center font-999-16 m_b10" @click="toBack()">{{ $t('jbXX.qux') }}</div>
    <div class="btn2 align-center font-fff-16" @click="submit()">{{ $t('jbXX.qr') }}</div>
  </div>
</template>

<script>
import { updateBaseInfoForH5 } from '../api'
export default {
  data() {
    return {
      user_name: '',
      user_xing: ''
    }
  },
  methods: {
    submit() {
        if (this.user_name == '') {
        this.$toast(this.$t('logon.dl17'))
        return;
      }
     
      updateBaseInfoForH5({
        user_name: this.user_name,
        user_xing: '',
      }).then((data) => {
        if (data.status == 200) {
          this.$toast(this.$t('logon.dl28'))
          this.$router.go(-1)
        }
      })
    }
  },
  mounted() {
    this.user_name = this.$route.params.user_name;
    this.user_xing = '';
  },

}
</script>


<style scoped>
.bg1 {
  width: 100%;
  height: 100vh;
  background: url("@/assets/bg1.png") no-repeat left top;
  background-size: 95% auto;
}
.input_out {
  height: 54px;
  border-radius: 10px;
  border: 1px solid #979797;
  box-sizing: border-box;
  padding: 6px 12px;
}

#login {
  height: 100vh;
}

input[type="text"],
input[type="password"],
input[type="tel"] {
  background: rgba(0, 0, 0, 0);
  height: 22px;
  border-radius: none;
  border: none;
  box-sizing: border-box;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
  outline: none;
  display: block;
  width: -webkit-fill-available;
}

input[type="text"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder {
  color: #999;
}

.btn1 {
  height: 54px;
  background: #eee;
  border-radius: 27px;
}

.btn2 {
  height: 54px;
  background: #018ecf;
  border-radius: 27px;
}
</style>