<template>
  <div>
    <div class="header before">
      <img :src="require('@/assets/leftArrow.png')" class="leftArrow"  @click="toBack()">
      <div class="hearderTitle">{{ $t('headGRZXObj.wdsc') }}</div>
    </div>
    <div class="del flex-wrap">
      <div class="flex-con"></div>
      <div style="display:none;">delete</div>
    </div>
    <div class="box">

      <div class="goods" @click="fnGoodsDetails(item.goods_id)" v-for="(item, index) in list" :key="index">
        <input type="checkbox" name="checkbox" checked v-model="item.checked" @change="fnCancle(item)">
        <img :src="item.goods_thumb"  class="goodsImg">
        <div class="title aui-ellipsis-2">{{ $i18n.locale == 'en' ? item.goods_name_en: $i18n.locale == 'es' ? item.goods_name_es : item.goods_name_tp }}</div>
        <div class="price">$ {{ item.goods_cu_price || '' }}</div>
      </div>
   
    </div>

  </div>
</template>
    
<script>
import { myCollect, doCollect } from '../api'
export default {
  data() {
    return {
      page: 1,
      count: 0,
      list: [],
      loading: true,
      finished: false,
      refreshing: false,
    }
  },
  methods: {
    initData() {
      myCollect({
        page: this.page
      }).then((data) => {
        if (data.status == 200) {
          this.count = data.count;
          if (this.refreshing) {
            this.list = data.msg
            this.refreshing = false
            if (this.list.length >= this.count) {
              this.finished = true
            }
            return
          }
          this.list = this.list.concat(data.msg)
          this.loading = false
          if (this.list.length >= this.count) {
            this.finished = true
          }
        }
      })
    },
    fnCancle(item) {
            let params = {
                goods_id: item.goods_id
            }
            doCollect(params).then((data) => {
                if (data.status == 200) {
                  this.list=[];
                    this.page=1;
                    this.initData();
                }
            })
        },
    onLoad() {
      this.page++
      this.initData()
    },
    onRefresh() {
      this.finished = false
      this.loading = true
      this.page = 1
      this.initData()
    },
  },
  mounted() {
    this.initData()
  },

}
    </script>
    
    
  <style scoped>
.box {
  padding: 0 16px;
}
.del {
  padding: 16px 16px 12px;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 20px;
}
.goods {
  position: relative;
  display: inline-block;
  width: calc((100% - 10px) / 2);
  background: #f8f8f8;
  border-radius: 4px;
  padding: 14px 10px;
  margin-bottom: 10px;
  height:230px;
  vertical-align: top;
}
.goods:nth-child(2n + 1) {
  margin-right: 10px;
}
.goodsImg {
  width: 100%;
  height: 138px;
  margin-bottom: 10px;
}
.title {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 16px;
  margin-bottom: 6px;
}
.price {
  font-weight: 500;
  font-size: 16px;
  color: #333333;
}
input[type="checkbox"] {
   position: absolute;
   top:10px;
   right:0;
  -webkit-appearance: none;
  width: 24px;
  height: 24px;
  border: 2px solid #979797;
  border-radius: 1px;
  margin-right: 16px;
}

input[type="checkbox"]:checked {
  border: 2px solid #018ecf;
  background: url("@/assets/checked.png") no-repeat center center;
  background-size: 18px 18px;
}
</style>