<template>
  <div>
    <div class="header before">
      <img :src="require('@/assets/leftArrow.png')" class="leftArrow" @click="toBack()">
      <div class="hearderTitle">submit order</div>
    </div>
    <div class="box">
      <div class="name">{{ $t('footAll.dz') }}</div>
      <div class="card flex-wrap" v-show="addressObj.addr_id" @click="fnAddress(1)">
        <div class="flex-con">
          <div class="aname" style="font-weight: 500;margin-bottom:8px;">{{ addressObj.addr_name }} {{ addressObj.addr_xing }}</div>
          <div style="margin-bottom:8px;">{{ addressObj.addr_address }} （{{ addressObj.addr_postal }}）</div>
          <div>{{ addressObj.addr_phone }}</div>
        </div>
        <img :src="require('@/assets/arrow1.png')" class="arrow">
      </div>
      <div class="card flex-wrap" v-show="!addressObj.addr_id" @click="fnAddress(1)">
        <div class="flex-con">
          <div>{{ $t('carObj.qxzdz') }}</div>
        </div>
        <img :src="require('@/assets/arrow1.png')" class="arrow">
      </div>
      <div class="name">{{ $t('orderObj.ddxx') }}</div>
      <div class="goods flex-wrap" v-for="(item, index) in data.msg" :key="index">
        <img :src="item.goods_thumb" class="goodsImg">
        <div class="before flex-con">
          <div class="title  flex-wrap">
            <div class="flex-con aui-ellipsis-2">{{ $i18n.locale == 'en' ?item.goods_name_en: $i18n.locale == 'es' ? item.goods_name_es : item.goods_name_tp }}</div>
          </div>
          <div class="info">{{ item.packaging_name }}</div>
          <div class="info">{{ item.spec_value }}</div>
          <div class="price flex-wrap">
            <div class="flex-con">$ {{ item.spec_cu_price }}</div>
            <div class="num">X {{ item.sc_number || goods_quantity }}</div>
          </div>
        </div>
      </div>
      <div class="priceAll flex-wrap">
        <div class="priceLeft flex-con">{{ $t('goodsObj.zj') }}</div>
        <div>${{ data.totalPrice }}</div>
      </div>
    </div>
    <div class="bottom">
      <div class="btn" @click="initCommitOrder">{{ $t('carObj.tj') }}</div>
    </div>
  </div>
</template>
      
<script>
import { showConfirmOrder, shopCarCommitOrder, showAddress, goodsCommitOrder } from '../api'
export default {
  data() {
    return {
      data: [],
      addressObj: {},
      addressObjNew: {},
      goods_quantity: this.$route.params.goods_quantity,
      fromName:'',
    }
  },
  methods: {
    initCommitOrder() {
      if (!this.addressObj.addr_id) {
        this.$message.error(this.$t('carObj.qxzdz'))
        return
      }

      let params
      if (this.$route.params.type == 1) {
        params = {
          sc_ids: this.$route.params.sc_ids,
          addr_id: this.addressObj.addr_id
        }
        shopCarCommitOrder(params).then((data) => {
          if (data.status == 200) {
            this.$router.push({ name: 'order', params: {status:0} })
          }
        })
      } else {
        params = {
          addr_id: this.addressObj.addr_id,
          goods_id: this.$route.params.goods_id,
          packaging_id: this.$route.params.packaging_id,
          spec_id: this.$route.params.spec_id,
          goods_quantity: this.goods_quantity
        }
        goodsCommitOrder(params).then((data) => {
          if (data.status == 200) {
            this.$router.push({ name: 'order', params: {status:0} })
          }
        })
      }

    },
    initData() {
      let params = {
        sc_ids: this.$route.params.sc_ids,
        goods_id: this.$route.params.goods_id,
        packaging_id: this.$route.params.packaging_id,
        spec_id: this.$route.params.spec_id,
        goods_quantity: this.goods_quantity
      }
      showConfirmOrder(params).then((data) => {
        if (data.status == 200) {
          this.data = data
        }
      })
    },
    initShowAddress() {
      let params = {}
      showAddress(params).then((data) => {
        if (data.status == 200) {
          this.addressObj = data.msg;
        }
      })
    },
    changeNums(e) {

    },
    fnAddress(type) {
      this.$router.push({ name: 'addressList', params: { type: type } })
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      console.log(from.name);
        vm.fromName = from.name
    })
  },
  mounted() {

    this.initData()
    if(this.fromName=='goodsDetail' || this.fromName==null || this.fromName=='shoppingCar'){
      localStorage.setItem('wlAddress', '')
      this.initShowAddress()
    }else{
      this.addressObj=JSON.parse(localStorage.getItem('wlAddress'));
    }
    
    
  },

}
  </script>
  <style scoped>
.box {
  padding: 0 16px 50px;
  margin-bottom: 78px;
}
.goods {
  padding-bottom: 10px;
  border-bottom: 1px solid #e3e3e3;
  margin-top: 10px;
}
.goodsImg {
  width: 138px;
  height: 138px;
  margin-right: 10px;
}
.title {
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  line-height: 16px;
  align-items: center;
  margin-top: 5px;
}

.info {
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  margin-top: 6px;
}
.price {
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  align-items: center;
  margin-top: 16px;
}
.num {
  font-size: 14px;
}
.name {
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  margin-top: 12px;
}
.card {
  background: #f8f9fc;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  line-height: 20px;
  align-items: center;
}
.arrow {
  width: 24px;
  height: 24px;
}
.priceAll {
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  padding: 10px 0;
}
.priceLeft {
  color: #666666;
}
.bottom {
  padding: 12px 16px;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background: #fff;
}
.btn {
  height: 54px;
  background: #018ecf;
  border-radius: 31px;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  line-height: 54px;
  text-align: center;
}
</style>